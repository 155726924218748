import { FaInfoCircle } from "react-icons/fa";
import styled from "styled-components";
import { Title } from "../../../assets/style/Title";

export const ManagerSearchHelper = () => {
  return (
    <InstructionContainer>
      <InstructionContent>
        <InstructionTitle>
          <InstructionIcon>
            <FaInfoCircle size={30} />
          </InstructionIcon>
          <span>How to search manager info?</span>
        </InstructionTitle>
        <InstructionBody>
          If this is your first time searching:
          <InstructionList>
            <li>Go to the FPL website and find the manager you want to search for.</li>
            <li>Copy the manager ID from the URL.</li>
            <li>Paste the manager ID in the search bar and click the search button.</li>
          </InstructionList>
          {/* If you have searched before:
          <InstructionList>
            <li>Search your manager ID or manager name in the search bar.</li>
          </InstructionList> */}
        </InstructionBody>
      </InstructionContent>
    </InstructionContainer>
  );
};

const InstructionContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const InstructionContent = styled.div`
  text-align: left;
`;

const InstructionTitle = styled(Title)`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const InstructionIcon = styled.div`
  margin-right: 1rem;
`;

const InstructionBody = styled.div`
  font-size: 1rem;
  line-height: 1.5;
`;

const InstructionList = styled.ul`
  padding-left: 1.5rem;
  margin-top: 0.5rem;

  li {
    margin-bottom: 0.5rem;
  }
`;

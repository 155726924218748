import React, { useState } from "react";
import styled from "styled-components";
import colors from "../../../assets/colors/colors";
import { fontSize } from "../../../assets/style/font";
import { useRecoilValue } from "recoil";
import { dataState } from "../../../state/data";
import { FaSort, FaSortUp, FaSortDown, FaTrophy } from "react-icons/fa";
import PlayerModal from "../../../components/PlayerModal";
import { usePlayerModal } from "../../../hooks/usePlayerModal";

interface PlayerPointsData {
  managerId: number;
  playerPointsData: Array<PlayerPoints>;
}

interface PlayerPoints {
  playerId: string;
  totalPoints: number;
  rounds: number;
  pointsPerRound: string;
  rank: number;
}

export const MostPointsPlayers: React.FC<PlayerPointsData> = ({ managerId, playerPointsData }) => {
  const data = useRecoilValue(dataState);
  const { isModalOpen, playerData, teamData, openModal, closeModal } = usePlayerModal(); // ✅ 모달 상태 관리

  const [sortConfig, setSortConfig] = useState({ key: "rank", direction: "asc" });
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 15;

  const handleSort = (key: string) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = [...playerPointsData].sort((a: any, b: any) => {
    if (sortConfig.key === "pointsPerRound") {
      return parseFloat(a[sortConfig.key]) < parseFloat(b[sortConfig.key])
        ? sortConfig.direction === "asc"
          ? -1
          : 1
        : parseFloat(a[sortConfig.key]) > parseFloat(b[sortConfig.key])
        ? sortConfig.direction === "asc"
          ? 1
          : -1
        : 0;
    }
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  const paginatedData = sortedData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const totalPages = Math.ceil(playerPointsData.length / itemsPerPage);
  const handlePageChange = (page: number) => setCurrentPage(page);

  const renderSortIcon = (key: string) => {
    if (sortConfig.key !== key) return <FaSort />;
    return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  const getPlayerInfo = (id: number) => {
    return data.allPlayerInfo.find((player: any) => player.id === id);
  };

  return (
    <Container>
      {isModalOpen && (
        <PlayerModal
          isOpen={isModalOpen}
          onClose={closeModal}
          playerInfo={getPlayerInfo(playerData.id)}
          teamInfo={teamData}
          allTeamInfo={data.allTeamInfo}
          currentRound={data.currentRound}
        />
      )}

      <Header>
        <FaTrophy style={{ marginRight: "0.5rem" }} />
        My MVPs
      </Header>
      <TableContainer>
        <Table>
          <thead>
            <TableHeaders>
              <TableHeader onClick={() => handleSort("rank")}>
                <div>Rank {renderSortIcon("rank")}</div>
              </TableHeader>
              <TableHeader onClick={() => handleSort("playerId")}>
                <div>Player {renderSortIcon("playerId")}</div>
              </TableHeader>
              <TableHeader onClick={() => handleSort("totalPoints")}>
                <div>Total {renderSortIcon("totalPoints")}</div>
              </TableHeader>
              <TableHeader onClick={() => handleSort("rounds")}>
                <div>Rounds {renderSortIcon("rounds")}</div>
              </TableHeader>
              <TableHeader onClick={() => handleSort("pointsPerRound")}>
                <div>My Avg {renderSortIcon("pointsPerRound")}</div>
              </TableHeader>
              <TableHeader onClick={() => handleSort("pointsPerRound")}>
                <div>Season Avg {renderSortIcon("pointsPerRound")}</div>
              </TableHeader>
            </TableHeaders>
          </thead>
          <tbody>
            {paginatedData.map((player) => {
              const playerInfo = data.allPlayerInfo.find((info: any) => info.id === parseInt(player.playerId));
              if (!playerInfo) return null;

              return (
                <TableRow key={player.playerId} onClick={() => openModal(playerInfo)}>
                  <TableCell>{player.rank}</TableCell>
                  <TableCell>{playerInfo.web_name}</TableCell>
                  <TableCell>{player.totalPoints}</TableCell>
                  <TableCell>{player.rounds}</TableCell>
                  <TableCell>{player.pointsPerRound}</TableCell>
                  <TableCell>{playerInfo.points_per_game}</TableCell>
                </TableRow>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>
      <Pagination>
        {Array.from({ length: totalPages }, (_, i) => (
          <PageButton key={i} onClick={() => handlePageChange(i + 1)} isActive={currentPage === i + 1}>
            {i + 1}
          </PageButton>
        ))}
      </Pagination>
    </Container>
  );
};

export default MostPointsPlayers;

// Styled Components
const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  background: ${colors.infoBoxBackground3};
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h2`
  text-align: center;
  color: ${colors.textBlack};
  font-size: ${fontSize.media_large.large};
  margin-bottom: 1.5rem;
`;

const TableContainer = styled.div`
  width: 100%;

  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  min-width: 600px; /* Ensure table doesn't collapse too much on small screens */

  thead {
    background: ${colors.primary};
    color: ${colors.textWhite};
  }

  th,
  td {
    padding: 0.5rem 0.5rem;
    text-align: center;
  }

  th {
    font-weight: bold;
    font-size: ${fontSize.media_large.small};
    cursor: pointer;
  }
`;

const TableHeaders = styled.tr``;

const TableHeader = styled.th`
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &:hover {
    background: ${colors.buttonHover};
    color: ${colors.textBlack};
  }
`;

const TableRow = styled.tr`
  background: ${colors.tableRow};
  border-radius: 8px;
  transition: background 0.3s;
  cursor: pointer;

  &:hover {
    background: ${colors.baseHover};
  }
`;

const TableCell = styled.td`
  font-size: ${fontSize.media_large.small};
  color: ${colors.textBlack};
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.75rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const PageButton = styled.button<{ isActive: boolean }>`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  background: ${({ isActive }) => (isActive ? colors.primary : colors.buttonBase)};
  color: ${colors.textWhite};
  font-size: ${fontSize.media_large.small};
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: ${colors.buttonHover};
  }
`;

import { useState } from "react";
import { useRecoilValue } from "recoil";
import { dataState } from "../state/data";

export const usePlayerModal = () => {
  const data = useRecoilValue(dataState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [playerData, setPlayerData] = useState<any>(null);
  const [teamData, setTeamData] = useState<any>(null);

  const openModal = (player: any) => {
    const teamInfo = data.allTeamInfo.find((team: any) => team.id === player.team);
    setPlayerData(player);
    setTeamData(teamInfo);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setPlayerData(null);
    setTeamData(null);
  };

  return {
    isModalOpen,
    playerData,
    teamData,
    openModal,
    closeModal,
  };
};

import { useEffect, useState } from "react";
import styled from "styled-components";
import { getPlayerImgSrcById } from "../../../utils/getPlayerImgSrcById";
import { NumericText, fontSize } from "../../../assets/style/font";
import { Title } from "../../../assets/style/Title";
import colors from "../../../assets/colors/colors";
import PlayerModal from "../../../components/PlayerModal";
import { useRecoilValue } from "recoil";
import { dataState } from "../../../state/data";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";

const Transfer = () => {
  const [transferInData, setTransferInData] = useState([]);
  const [transferOutData, setTransferOutData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<any>(null);
  const [teamData, setTeamData] = useState<any>(null);
  const data = useRecoilValue(dataState);

  const collectData = async () => {
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
    const transferInResponse = await fetch(`${serverUrl}/api/player/top-players/transfer-in`);
    const transferOutResponse = await fetch(`${serverUrl}/api/player/top-players/transfer-out`);

    setTransferInData((await transferInResponse.json()).slice(0, 5));
    setTransferOutData((await transferOutResponse.json()).slice(0, 5));
  };

  const handlePlayerClick = (player: any) => {
    const teamData = data.allTeamInfo.find((team: any) => team.id === player.team);
    setTeamData(teamData);
    setSelectedPlayer(player);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedPlayer(null);
  };

  useEffect(() => {
    collectData();
  }, []);

  const getPlayerInfo = (id: number) => {
    return data.allPlayerInfo.find((player: any) => player.id === id);
  };

  return (
    <TransferContainer>
      {isModalOpen && selectedPlayer && (
        <PlayerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          playerInfo={getPlayerInfo(selectedPlayer.id)}
          allTeamInfo={data.allTeamInfo}
          teamInfo={teamData}
          currentRound={data.currentRound}
        />
      )}
      <TransferRowWrapper>
        <TransferSection>
          <ListTitle>📈 Most Transferred In</ListTitle>
          {transferInData.map((player: any) => (
            <TransferRow key={player.id} onClick={() => handlePlayerClick(player)}>
              <FaArrowUp color="limegreen" size={16} />
              <PlayerImg src={getPlayerImgSrcById(player.code)} alt={player.web_name} />
              <PlayerName>{player.web_name}</PlayerName>
              <PlayerPoints>+{player.transfers_in_event.toLocaleString()}</PlayerPoints>
            </TransferRow>
          ))}
        </TransferSection>

        <TransferSection>
          <ListTitle>📉 Most Transferred Out</ListTitle>
          {transferOutData.map((player: any) => (
            <TransferRow key={player.id} onClick={() => handlePlayerClick(player)}>
              <FaArrowDown color="orangered" size={16} />
              <PlayerImg src={getPlayerImgSrcById(player.code)} alt={player.web_name} />
              <PlayerName>{player.web_name}</PlayerName>
              <PlayerPoints>-{player.transfers_out_event.toLocaleString()}</PlayerPoints>
            </TransferRow>
          ))}
        </TransferSection>
      </TransferRowWrapper>
    </TransferContainer>
  );
};

const TransferContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  width: 100%;
`;

const TransferRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1.5rem;
  }
`;

const TransferSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  border-radius: 8px;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ListTitle = styled.div`
  font-size: ${fontSize.media_large.medium};
  font-weight: bold;
  color: ${colors.textWhite};
`;

const TransferRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${colors.baseHover};
  }
`;

const PlayerImg = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
`;

const PlayerName = styled.div`
  flex: 1;
  color: ${colors.textWhite};
  font-weight: 500;
`;

const PlayerPoints = styled(NumericText)`
  font-size: 0.9rem;
  font-weight: bold;
  color: ${colors.textWhite};
`;

export default Transfer;

import React from "react";
import styled from "styled-components";
import colors from "../assets/colors/colors";
import { Link } from "react-router-dom";

const PromotionNavLink = () => {
  const leagueCode = "t0fhxl";

  const handleCopyCode = () => {
    navigator.clipboard.writeText(leagueCode).then(() => {
      alert("Code copied to clipboard!");
    });
  };

  return (
    <Container>
      <StyledLink
        to="https://fantasy.premierleague.com/leagues/auto-join/t0fhxl"
        target="_blank"
        rel="noopener noreferrer"
      >
        Join League
      </StyledLink>
      <CopyButton onClick={handleCopyCode}>Copy Code</CopyButton>
    </Container>
  );
};

export default PromotionNavLink;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const StyledLink = styled(Link)`
  background-color: ${colors.buttonBase2};
  color: ${colors.textBlack};
  text-decoration: none;
  padding: 0.4rem 0.8rem;
  border-radius: 5px;
  font-size: 0.85rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${colors.buttonHover};
    color: ${colors.textWhite};
  }
`;

const CopyButton = styled.button`
  background-color: ${colors.buttonBackground};
  color: ${colors.textBlack};
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.85rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${colors.buttonHover};
    color: ${colors.textWhite};
  }

  &:focus {
    outline: none;
  }
`;

import { getTeamNameByCode, getTeamNameById } from "./getTeamNameById";
import liverpool from "../assets/image/logos/liverpool.svg";
import arsenal from "../assets/image/logos/arsenal.svg";
import astonVilla from "../assets/image/logos/astonvilla.svg";
import brighton from "../assets/image/logos/brighton.svg";
import burnley from "../assets/image/logos/burnley.svg";
import brentford from "../assets/image/logos/brentford.svg";
import chelsea from "../assets/image/logos/chelsea.svg";
import crystalPalace from "../assets/image/logos/crystalpalace.svg";
import everton from "../assets/image/logos/everton.svg";
import fulham from "../assets/image/logos/fulham.svg";
import mancity from "../assets/image/logos/mancity.svg";
import manutd from "../assets/image/logos/manutd.svg";
import lutontown from "../assets/image/logos/lutontown.svg";
import nottingham from "../assets/image/logos/nottingham.svg";
import tottenham from "../assets/image/logos/tottenham.svg";
import wolves from "../assets/image/logos/wolves.svg";
import newcastle from "../assets/image/logos/newcastle.svg";
import westham from "../assets/image/logos/westham.svg";
import sheffield from "../assets/image/logos/sheffield.svg";
import bournemouth from "../assets/image/logos/bournemouth.svg";
import leicester from "../assets/image/logos/leicester.svg";
import southampton from "../assets/image/logos/southampton.svg";
import ipswich from "../assets/image/logos/ipswich.svg";

interface TeamLogos {
  [key: string]: string;
}

const teamLogos: TeamLogos = {
  liverpool: liverpool, // Replace with the correct path to your logo
  arsenal: arsenal, // add other teams here
  astonvilla: astonVilla, // add other teams here
  brighton: brighton, // add other teams here
  burnley: burnley, // add other teams here
  brentford: brentford, // add other teams here
  bournemouth: bournemouth, // add other teams here
  chelsea: chelsea, // add other teams here
  crystalpalace: crystalPalace, // add other teams here
  everton: everton, // add other teams here
  fulham: fulham, // add other teams
  mancity: mancity, // add other teams here
  manutd: manutd, // add other teams here
  luton: lutontown, // add other teams here
  nottmforest: nottingham, // add other teams here
  spurs: tottenham, // add other teams here
  newcastle: newcastle, // add other teams here
  wolves: wolves, // add other teams here
  westham: westham, // add other teams here
  sheffieldutd: sheffield, // add other teams here
  leicester: leicester, // add other teams here
  southampton: southampton, // add other teams here
  ipswich: ipswich, // add other teams here
};

// 알파벳 순서
export const teamNames = [
  "arsenal",
  "astonvilla",
  "Bournemouth",
  "Brentford",
  "Brighton",
  "Chelsea",
  "CrystalPalace",
  "Everton",
  "Fulham",
  "Ipswich",
  "Leicester",
  "Liverpool",
  "Luton",
  "mancity",
  "manutd",
  "Newcastle",
  "nottmforest",
  "Southampton",
  "Spurs",
  "WestHam",
  "Wolves",
];

export const getTeamLogoById = (teams: any, id: number, type: string) => {
  let teamName;
  if (type === "code") {
    teamName = getTeamNameByCode(teams, id);
  } else if (type === "id") {
    teamName = getTeamNameById(teams, id);
  }
  teamName = teamName.toLowerCase();
  // delete whitespace
  teamName = teamName.replace(/\s/g, "");
  // delete ' in team name
  teamName = teamName.replace("'", "");
  // Check if the team logo exists in the mapping
  if (teamLogos[teamName]) {
    return teamLogos[teamName];
  } else {
    return teamLogos["liverpool"];
  }
};

export const getTeamLogoByName = (teamName: string) => {
  if (!teamName) {
    return teamLogos["arsenal"];
  }
  teamName = teamName.toLowerCase();
  // delete whitespace
  teamName = teamName.replace(/\s/g, "");
  // delete ' in team name
  teamName = teamName.replace("'", "");
  // Check if the team logo exists in the mapping
  if (teamLogos[teamName]) {
    return teamLogos[teamName];
  } else {
    return teamLogos["liverpool"];
  }
};

const teamColors: { [key: string]: string } = {
  arsenal: "#ec696d", // Lighter red
  astonvilla: "#B0D5E5", // Lighter blue
  brentford: "#FFE199", // Lighter yellow
  brighton: "#6495ED", // Cornflower blue
  bournemouth: "#FFD699", // Lighter orange
  burnley: "#a17084", // Lighter maroon
  chelsea: "#5B86E5", // Lighter blue
  crystalpalace: "#507BC9", // Lighter blue
  everton: "#66A3FF", // Sky blue
  fulham: "#808080", // Gray
  liverpool: "#FF7F7F", // Lighter red
  mancity: "#A5D3E8", // Lighter blue
  manutd: "#FF9999", // Lighter red
  luton: "#ee6060", // Lighter red
  newcastle: "#3D3D3D", // Dark gray
  nottmforest: "#FFD699", // Lighter orange
  spurs: "#4b87c2", // Darker blue
  sheffieldutd: "#FFB3B3", // Lighter pink
  westham: "#c75c5c", // Darker red
  wolves: "#FFD699", // Lighter orange
  leicester: "#195edf", // blue
  southampton: "#d71920", // red
  ipswich: "#2963bb", // blue
};
export default teamColors;

import styled from "styled-components";
import { useEffect, useState } from "react";
import { dataState } from "../../../state/data";
import { useRecoilValue } from "recoil";
import { getManagerTransferSuggestions } from "../../../api/manager/getManagerTransferSuggestions";
import colors from "../../../assets/colors/colors";
import { getTeamLogoById } from "../../../utils/getTeamLogoById";
import PlayerModal from "../../../components/PlayerModal";
import { fontSize } from "../../../assets/style/font";

interface TransferSuggestionsBoxProps {
  managerData: any;
}

interface TransferSuggestion {
  inPlayerId: number;
  outPlayerId: number;
  scoreDiff: number;
}

export const TransferSuggestionsBox: React.FC<TransferSuggestionsBoxProps> = ({ managerData }) => {
  const [transferSuggestions, setTransferSuggestions] = useState<TransferSuggestion[]>([]);
  const data = useRecoilValue(dataState);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [playerData, setPlayerData] = useState<any>(null);
  const [teamData, setTeamData] = useState<any>(null);

  const fetchTransferSuggestions = async () => {
    const result = await getManagerTransferSuggestions(managerData.id, data.currentRound);
    setTransferSuggestions(result.transferSuggestions);
  };

  const getPriority = (scoreDiff: number) => {
    if (scoreDiff > 12) {
      return "High";
    } else if (scoreDiff > 10) {
      return "Medium";
    } else {
      return "Low";
    }
  };

  const handleOpenModal = (playerId: any) => {
    setIsModalOpen(true);
    const playerData = data.allPlayerInfo.find((player: any) => player.id === playerId);
    setPlayerData(playerData);
    const teamData = data.allTeamInfo.find((team: any) => team.id === playerData.team);
    setTeamData(teamData);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchTransferSuggestions();
  }, [managerData]);

  return (
    <Container>
      {isModalOpen && (
        <PlayerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          playerInfo={playerData}
          allTeamInfo={data.allTeamInfo}
          teamInfo={teamData}
          currentRound={data.currentRound}
        />
      )}
      <Title>Transfer Suggestions</Title>
      <Body>
        <List>
          {data.allPlayerInfo &&
            transferSuggestions.map((transferSuggestion, index) => (
              <TransferSuggestionCard key={index}>
                <PlayerDetails>
                  <PlayerButton onClick={() => handleOpenModal(transferSuggestion.inPlayerId)}>
                    <Label>IN:</Label>
                    <TeamLogo
                      src={getTeamLogoById(
                        data.allTeamInfo,
                        data.allPlayerInfo.find((player: any) => player.id === transferSuggestion.inPlayerId)?.team,
                        "id"
                      )}
                      alt="logo"
                    />
                    <PlayerName>
                      {data.allPlayerInfo.find((player: any) => player.id === transferSuggestion.inPlayerId)?.web_name}
                    </PlayerName>
                  </PlayerButton>
                  <PlayerButton onClick={() => handleOpenModal(transferSuggestion.outPlayerId)}>
                    <Label>OUT:</Label>
                    <TeamLogo
                      src={getTeamLogoById(
                        data.allTeamInfo,
                        data.allPlayerInfo.find((player: any) => player.id === transferSuggestion.outPlayerId)?.team,
                        "id"
                      )}
                      alt="logo"
                    />
                    <PlayerName>
                      {data.allPlayerInfo.find((player: any) => player.id === transferSuggestion.outPlayerId)?.web_name}
                    </PlayerName>
                  </PlayerButton>
                </PlayerDetails>
                <Priority priority={getPriority(transferSuggestion.scoreDiff)}>
                  <span>Priority :</span> {getPriority(transferSuggestion.scoreDiff)}
                </Priority>
              </TransferSuggestionCard>
            ))}
        </List>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${colors.infoBoxBackground2};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 1600px) {
    margin-top: 1rem;
  }
`;

const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-top: 1rem;
  text-align: center;

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.large};
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-bottom: 1rem;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TransferSuggestionCard = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${colors.infoBoxBackground};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const PlayerDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PlayerButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.3rem 0.5rem;
  background-color: ${colors.buttonBase};
  color: ${colors.textBlack};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.highlightColor};
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 4px 2px ${colors.highlightColor};
  }

  &:active {
    transform: scale(0.98);
  }

  @media (max-width: 768px) {
    padding: 0.2rem 0.5rem;
  }
`;

const Label = styled.span`
  font-size: 0.9rem;
  font-weight: bold;
  color: ${colors.textBlack};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.small};
  }
`;

const TeamLogo = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;

  @media (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const PlayerName = styled.span`
  font-size: 1rem;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium};
  }
`;

const Priority = styled.span<{ priority: string }>`
  font-size: 1rem;
  font-weight: bold;
  color: ${({ priority }) => (priority === "High" ? "#e74c3c" : priority === "Medium" ? "#f39c12" : "#2ecc71")};
  background: ${colors.buttonHoverLight};
  padding: 4px 8px;
  border-radius: 5px;
  text-align: center;

  span {
    color: ${colors.textBlack};
  }

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium};
  }
`;

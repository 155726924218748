import axios from "axios";

export const getManagerTransferSuggestions = async (managerId: string, round: number) => {
  try {
    const serverUrl = process.env.REACT_APP_NEST_SERVER_ADDRESS;
    const url: string = `${serverUrl}/api/v2/manager/transfer-suggestions`;
    const response = await axios.post(url, {
      managerId: managerId,
      round: round,
      transferCount: 1, // TODO : 추후에 수정
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching data from FPL:", error);
  }
};

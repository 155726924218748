import React from "react";
import styled from "styled-components";
import InfoBox from "../../../components/common/InfoBox";

const MiniLeagueNews = () => {
  // 더미 데이터
  const news = [
    "Manager A has taken the top spot!",
    "Manager B made an incredible comeback this week .",
    "New managers joined the league. Welcome!",
  ];

  return (
    <InfoBox>
      <Title>Mini League News</Title>
      <NewsList>
        {news.map((item, index) => (
          <NewsItem key={index}>{item}</NewsItem>
        ))}
      </NewsList>
    </InfoBox>
  );
};

export default MiniLeagueNews;

const Title = styled.h2`
  margin-bottom: 1rem;
`;

const NewsList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const NewsItem = styled.li`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #333;
`;

import styled from "styled-components";
import colors from "../../assets/colors/colors";

export const PageContainer = styled.div`
  text-align: center;
  margin: auto;
  width: 95%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

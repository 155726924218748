import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { accountState } from "../state/account";
import colors from "../assets/colors/colors";
import logo from "../assets/logo9.png";
import { FaHome, FaQuestion, FaRegUser } from "react-icons/fa";
import { GiSoccerKick } from "react-icons/gi";
import { GrUserManager } from "react-icons/gr";
import { RiTeamLine } from "react-icons/ri";
import { MdExpandMore, MdExpandLess } from "react-icons/md";
import { optionState } from "../state/option";
import { Link } from "react-router-dom";
import PromotionBanner from "./PromotionBanner";
import { fontSize } from "../assets/style/font";
import { SiPremierleague } from "react-icons/si";
import Deadline from "./Deadline";
import { dataState } from "../state/data";

enum MenuOptions {
  PLAYER = "player",
  MANAGER = "manager",
}

interface MenuStates {
  player: boolean;
  manager: boolean;
}

interface SubMenuProps {
  menuExpanded: boolean;
}

const Navbar: React.FC = () => {
  const [menuStates, setMenuStates] = useState<MenuStates>({ player: false, manager: false });
  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(true);
  const accountInfo = useRecoilValue(accountState);
  const [options, setOptions] = useRecoilState(optionState);
  const data = useRecoilValue(dataState);
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const leagueCode = "t0fhxl";

  const toggleMenu = (menu: MenuOptions) => {
    setMenuStates((prevState) => ({ ...prevState, [menu]: !prevState[menu] }));
  };

  const handleCloseBanner = () => setIsBannerVisible(false);

  const handleCopyCode = () => {
    navigator.clipboard.writeText(leagueCode).then(() => {
      alert("Code copied to clipboard!");
    });
  };

  const handleSubLinkClick = (menuOption: keyof typeof options, searchType: any) => {
    setOptions((prevState: any) => ({ ...prevState, [menuOption]: searchType }));
  };

  return (
    <>
      <TopMenu>
        <Deadline round={data.currentRound + 1} />
      </TopMenu>
      <StyledNavbar>
        <Logo href="/">
          <LogoImg src={logo} alt="logo" />
          <span>FPL Universe</span>
        </Logo>
        <MobileToggle onClick={() => setIsMobileNavOpen((prev) => !prev)}>☰</MobileToggle>
        <NavLinks isOpen={isMobileNavOpen}>
          <NavLinkItem to="/" icon={<FaHome />} label="Home" />
          <ExpandableMenu
            icon={<GiSoccerKick />}
            label="Player"
            isExpanded={menuStates.player}
            onToggle={() => toggleMenu(MenuOptions.PLAYER)}
          >
            {[
              "BEST Picks",
              "Best Differentials",
              "Top Transfer In",
              "Top Transfer Out",
              "Underpriced Forward",
              "Underpriced Midfielder",
              "Underpriced Defender",
              "Underpriced Goalkeeper",
            ].map((label, index) => (
              <SubLinkItem
                key={index}
                to="/player"
                label={label}
                onClick={() => handleSubLinkClick("playerMenuOption", index)}
              />
            ))}
          </ExpandableMenu>

          <ExpandableMenu
            icon={<GrUserManager />}
            label="Manager"
            isExpanded={menuStates.manager}
            onToggle={() => toggleMenu(MenuOptions.MANAGER)}
          >
            <SubLinkItem
              to="/manager"
              label="Search Manager"
              onClick={() => handleSubLinkClick("managerMenuOption", "search")}
            />
            <SubLinkItem
              to="/manager"
              label="Top Managers"
              onClick={() => handleSubLinkClick("managerMenuOption", "topWorld")}
            />
          </ExpandableMenu>

          <NavLinkItem to="/league" icon={<SiPremierleague />} label="League" />

          <NavLinkItem to="/team" icon={<RiTeamLine />} label="Team" />

          <NavLinkItem to="/test" icon={<FaQuestion />} label="If you were PL Manager.." />
          {isBannerVisible && <PromotionBanner />}
        </NavLinks>
      </StyledNavbar>
    </>
  );
};

const StyledNavbar = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 0rem 1rem 0rem;
  height: 5rem;
  background-color: ${colors.navBackground};
  color: ${colors.textWhite};
  width: 80%;
  margin: auto;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 1rem;
    width: 100%;
    padding: 0;
  }
`;

const TopMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  background-color: ${colors.navBackground};
  color: ${colors.textWhite};
  font-size: ${fontSize.media_large.small};
`;

const Logo = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.textWhite};

  span {
    font-size: 1.7rem;
    margin-left: 0.5rem;
  }

  @media (max-width: 768px) {
    span {
      font-size: 1.5rem;
    }
  }
`;

const LogoImg = styled.img`
  width: 3rem;
`;

const NavLinks = styled.div<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    margin: auto;
    margin-top: 1rem;
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  }
`;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: ${colors.textWhite};
  border-radius: 5px;
  width: 100%;

  &:hover {
    background-color: ${colors.baseHover};
  }

  span {
    margin-left: 0.5rem;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 0rem;
  }
`;

const NavLinkItem: React.FC<{ to: string; icon: React.ReactNode; label: string }> = ({ to, icon, label }) => (
  <NavLink to={to}>
    <Icon>{icon}</Icon>
    <span>{label}</span>
  </NavLink>
);

const ExpandableMenu: React.FC<{
  icon: React.ReactNode;
  label: string;
  isExpanded: boolean;
  onToggle: () => void;
  children: React.ReactNode;
}> = ({ icon, label, isExpanded, onToggle, children }) => (
  <DropdownWrapper>
    <DropdownTrigger onClick={onToggle}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Icon>{icon}</Icon>
        <span>{label}</span>
      </div>
      {isExpanded ? <MdExpandLess /> : <MdExpandMore />}
    </DropdownTrigger>
    <SubMenu menuExpanded={isExpanded}>{children}</SubMenu>
  </DropdownWrapper>
);

const SubLinkItem: React.FC<{ to: string; label: string; onClick: () => void }> = ({ to, label, onClick }) => (
  <SubLink to={to} onClick={onClick}>
    <span>{label}</span>
  </SubLink>
);

const Icon = styled.div`
  font-size: 1.2rem;
  margin-right: 0.3rem;
`;

const DropdownWrapper = styled.div`
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const DropdownTrigger = styled.div`
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: ${colors.textWhite};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  width: 8rem;
  justify-content: space-between;

  &:hover {
    background-color: ${colors.baseHover};
  }

  span {
    margin-left: 0.5rem;
    margin-right: 0.3rem;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 0;
  }
`;

const SubMenu = styled.div<SubMenuProps>`
  display: ${({ menuExpanded }) => (menuExpanded ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${colors.navBackground};
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 999;
  width: 10rem;

  @media (max-width: 768px) {
    position: static;
    box-shadow: none;
    padding: 0.2rem 0 0.2rem 1rem;
    width: 80%;
  }
`;

const SubLink = styled(Link)`
  padding: 0.5rem;
  text-decoration: none;
  color: ${colors.textWhite};
  border-radius: 5px;

  &:hover {
    background-color: ${colors.baseHover};
  }
`;

const MobileToggle = styled.div`
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  user-select: none;

  @media (max-width: 768px) {
    display: block;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
`;

export default Navbar;

import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({ totalPages, currentPage, onPageChange }) => {
  const MAX_PAGE_BUTTONS = 5; // ✅ 고정된 버튼 개수 (이전/다음 포함 X)

  const pageNumbers = useMemo(() => {
    if (totalPages <= MAX_PAGE_BUTTONS) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    let startPage = Math.max(1, currentPage - Math.floor(MAX_PAGE_BUTTONS / 2));
    let endPage = Math.min(totalPages, startPage + MAX_PAGE_BUTTONS - 1);

    if (endPage === totalPages) {
      startPage = Math.max(1, endPage - MAX_PAGE_BUTTONS + 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  }, [currentPage, totalPages]);

  return (
    <PaginationContainer>
      <PageButton disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)}>
        <FaChevronLeft />
      </PageButton>

      {pageNumbers.map((num) => (
        <PageNumber key={num} isActive={currentPage === num} onClick={() => onPageChange(num)}>
          {num}
        </PageNumber>
      ))}

      <PageButton disabled={currentPage === totalPages} onClick={() => onPageChange(currentPage + 1)}>
        <FaChevronRight />
      </PageButton>
    </PaginationContainer>
  );
};

export default Pagination;

// ✅ Styled Components
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const PageButton = styled.button`
  background: #007bff;
  border: none;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const PageNumber = styled.button<{ isActive: boolean }>`
  background: ${({ isActive }) => (isActive ? "#007bff" : "transparent")};
  border: 1px solid #007bff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? "white" : "#007bff")};
  font-weight: bold;
`;

import React from "react";
import styled from "styled-components";
import InfoBox from "../../../components/common/InfoBox";
import PlayerTransferCard from "./PlayerTransferCard";

// 더미 데이터
const topTransfers = {
  in: [
    { player: "Player A", team: "Team X" },
    { player: "Player B", team: "Team Y " },
  ],
  out: [
    { player: "Player C", team: "Team Z" },
    { player: "Player D", team: "Team W" },
  ],
};

const MiniLeagueTopTransfers = () => {
  return (
    <InfoBox>
      <Title>Top Transfers</Title>
      <TransfersContainer>
        <TransfersSection>
          <Subtitle>Most Transferred In</Subtitle>
          {topTransfers.in.map((transfer, index) => (
            <PlayerTransferCard key={index} player={transfer.player} team={transfer.team} />
          ))}
        </TransfersSection>
        <TransfersSection>
          <Subtitle>Most Transferred Out</Subtitle>
          {topTransfers.out.map((transfer, index) => (
            <PlayerTransferCard key={index} player={transfer.player} team={transfer.team} />
          ))}
        </TransfersSection>
      </TransfersContainer>
    </InfoBox>
  );
};

export default MiniLeagueTopTransfers;

const Title = styled.h2`
  margin-bottom: 1rem;
`;

const TransfersContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TransfersSection = styled.div`
  width: 48%;
`;

const Subtitle = styled.h3`
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  color: #333;
`;

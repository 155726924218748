import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../assets/colors/colors";
import teamColors from "../assets/colors/teamColors";
import { getTeamLogoById, getTeamLogoByName } from "../utils/getTeamLogoById";
import { getTeamNameByCode, getTeamNameById, getTeamSymbolById } from "../utils/getTeamNameById";
import axios from "axios";

interface FixturesModalProps {
  isOpen: boolean;
  onClose: () => void;
  allTeamInfo: any;
  fixturesInfo: any; // Replace 'any' with the actual type of your teamInfo data
  currentRound: any;
}

interface ModalContentProps {
  teamColor?: string;
}

const FixturesModal: React.FC<FixturesModalProps> = ({ isOpen, onClose, allTeamInfo, fixturesInfo, currentRound }) => {
  const [playersData, setPlayersData] = useState([]);

  const getPlayersData = async () => {
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;

    let playerIds: any[] = [];
    for (let i = 0; i < fixturesInfo.stats[0].h.length; i++) {
      playerIds.push(fixturesInfo.stats[0].h[i].element);
    }
    for (let i = 0; i < fixturesInfo.stats[0].a.length; i++) {
      playerIds.push(fixturesInfo.stats[0].a[i].element);
    }
    for (let i = 0; i < fixturesInfo.stats[1].h.length; i++) {
      playerIds.push(fixturesInfo.stats[1].h[i].element);
    }
    for (let i = 0; i < fixturesInfo.stats[1].a.length; i++) {
      playerIds.push(fixturesInfo.stats[1].a[i].element);
    }
    for (let i = 0; i < fixturesInfo.stats[8].h.length; i++) {
      playerIds.push(fixturesInfo.stats[8].h[i].element);
    }
    for (let i = 0; i < fixturesInfo.stats[8].a.length; i++) {
      playerIds.push(fixturesInfo.stats[8].a[i].element);
    }

    // delete duplicate player ids without using set
    playerIds = playerIds.filter((value, index) => playerIds.indexOf(value) === index);

    let players: any = [];
    for (let i = 0; i < playerIds.length; i++) {
      const playerData: any = await axios.get(`${serverUrl}/api/player/${playerIds[i]}`);
      players.push(playerData.data);
    }
    setPlayersData(players);
  };

  useEffect(() => {
    if (!fixturesInfo) return;
    else getPlayersData();
  }, [fixturesInfo]);

  if (!isOpen || !fixturesInfo || !playersData) return null;

  const innerStatsContainer = (index: number) => {
    return (
      <StatsContainer>
        <StatItem>
          <StatValue>
            {fixturesInfo.stats[index].h.map((stat: any) => {
              return (
                <div>
                  {playersData &&
                    playersData.map((player: any) => {
                      if (player.id === stat.element) {
                        return (
                          <StatText>
                            <span>{player.web_name}</span>
                            <span>{stat.value}</span>
                          </StatText>
                        );
                      }
                    })}
                </div>
              );
            })}
          </StatValue>
        </StatItem>
        <StatItem>
          <StatValue>
            {fixturesInfo.stats[index].a.map((stat: any) => {
              return (
                <div>
                  {playersData &&
                    playersData.map((player: any) => {
                      if (player.id === stat.element) {
                        return (
                          <StatText>
                            <span>{stat.value}</span>
                            <span>{player.web_name}</span>
                          </StatText>
                        );
                      }
                    })}
                </div>
              );
            })}
          </StatValue>
        </StatItem>
      </StatsContainer>
    );
  };

  return (
    <ModalOverlay isOpen={isOpen} onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>

        <Header>
          <LeftHeader>
            <TeamInfoLeft>
              <TeamLogo src={getTeamLogoById(allTeamInfo, fixturesInfo.team_h, "id")} alt="logo"></TeamLogo>
              <Name>{getTeamNameById(allTeamInfo, fixturesInfo.team_h)}</Name>
            </TeamInfoLeft>
            <TeamScore>{fixturesInfo.team_h_score}</TeamScore>
          </LeftHeader>
          <Bar>-</Bar>
          <RightHeader>
            <TeamScore>{fixturesInfo.team_a_score}</TeamScore>
            <TeamInfoRight>
              <Name>{getTeamNameById(allTeamInfo, fixturesInfo.team_a)}</Name>
              <TeamLogo src={getTeamLogoById(allTeamInfo, fixturesInfo.team_a, "id")} alt="logo"></TeamLogo>
            </TeamInfoRight>
          </RightHeader>
        </Header>
        <Line></Line>
        <StatsTitle>Scorers</StatsTitle>
        {innerStatsContainer(0)}
        <StatsTitle>Assists</StatsTitle>
        {innerStatsContainer(1)}
        <StatsTitle>Bonus</StatsTitle>
        {innerStatsContainer(8)}

        {/* <Line></Line>
        <AdditionalInfo>
          <AdditionalInfoTitle>Fixtures</AdditionalInfoTitle>
          <AdditionalInfoContent></AdditionalInfoContent>
        </AdditionalInfo> */}
      </ModalContent>
    </ModalOverlay>
  );
};

// Define a function to calculate background color based on difficulty
const getBackgroundColor = (difficulty: number): string => {
  return colors.difficulties[difficulty];
};

const ModalOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: black;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 200;
`;

const ModalContent = styled.div<ModalContentProps>`
  //background-color: ;
  background: linear-gradient(to right, #50a3a2, #53e3a6); /* Green gradient */
  padding: 2rem;
  border-radius: 0.8rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  width: 100%;
  position: relative;

  @media (max-width: 768px) {
    width: 90%;
  }
  @media (min-width: 769px) {
    width: 50%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;

const LeftHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Bar = styled.div`
  color: ${colors.baseColor};
  font-size: 2rem;
  font-weight: bold;
`;

const TeamLogo = styled.img`
  width: 6rem;
  border-radius: 25%;

  @media (max-width: 1268px) {
    width: 3rem;
  }

  @media (max-width: 500px) {
    width: 2rem;
  }
`;

const TeamInfoLeft = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const TeamInfoRight = styled(TeamInfoLeft)`
  justify-content: right;
`;

const TeamScore = styled.div`
  color: ${colors.baseColor};
  font-size: 2rem;
  font-weight: bold;
`;

const StatText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
  padding: 0rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const RightHeader = styled(LeftHeader)``;

const Name = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: ${colors.baseColor};
  margin: 0 1rem;
  text-align: center;
  // overflow
  white-space: wrap;

  @media (max-width: 1268px) {
    font-size: 1.5rem;
  }
  @media (max-width: 500px) {
    font-size: 1rem;
  }
`;

const BasicInfo = styled.div`
  color: ${colors.baseColor};
  margin-bottom: 5px;
  display: flex;
  justify-content: space-evenly;

  span {
    margin-right: 5px;
    font-size: 1rem;
  }
`;

const Line = styled.hr`
  border: none;
  width: 100%;
  height: 2px;
  background-color: ${colors.baseColor};
  margin: 20px 0;
`;

const CloseButton = styled.button`
  background-color: ${colors.closeButton};
  color: #fff;
  border: none;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
`;

const StatsTitle = styled.div`
  color: ${colors.baseColor};
  font-weight: bold;
  font-size: 1.5rem;
  margin: 20px 0;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 2rem 0;
`;

const StatItem = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 0.3rem;
  justify-content: space-between;
`;

const StatLabel = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
  font-size: 1rem;
`;

const StatValue = styled.div`
  color: ${colors.baseColor};
  font-size: 1.5rem;
  font-weight: bold;
  text-align: right;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${colors.baseColor};
`;

const AdditionalInfoTitle = styled.div`
  color: ${colors.baseColor};
  font-weight: bold;
  font-size: 1.3rem;
`;

const AdditionalInfoContent = styled.div`
  color: ${colors.baseColor};
  display: flex;
  flex-direction: row;
  overflow-x: auto; /* Enable horizontal scrolling */
  /* Optionally, you can add padding and other styles as needed */
`;

const FixturesBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 1rem;
  font-weight: bold;
  transition: background-color 0.3s ease;
  @media (max-width: 1268px) {
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Title = styled.div`
  color: ${colors.baseColor};
  font-weight: bold;
  text-align: left;
  font-size: 1rem;
`;

const Content = styled.div`
  color: #fff;
  text-align: right;
  width: 100%;
`;

const TeamName = styled.div<{ difficulty: number }>`
  color: #fff;
  background-color: ${({ difficulty }) => getBackgroundColor(difficulty)}; // Dynamic background color
  text-align: center;
  font-weight: bold;
`;

export default FixturesModal;

import styled from "styled-components";
import colors from "../colors/colors";
import { fontSize } from "./font";

export const Title = styled.h1`
  font-size: ${fontSize.media_large.medium}; // Larger font size for emphasis
  color: ${colors.baseText};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium}; // Smaller font size for smaller screens
  }
`;

import { JwtPayload, jwtDecode } from "jwt-decode";

export const checkJWTExpire = (token: string) => {
  if (token) {
    const decodedToken: JwtPayload = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
    const expireTime = decodedToken.exp ? decodedToken.exp : 0;
    if (expireTime > currentTime) {
      localStorage.removeItem("token");
      return true;
    } else {
      return false;
    }
  }
};

import styled from "styled-components";
import colors from "../colors/colors";

export const NumericText = styled.span`
  color: ${colors.baseText};
`;

export const fontSize = {
  media_small: {
    tiny: "0.6rem",
    small: "0.65rem",
    medium: "0.75rem",
    large: "1.1rem",
  },
  media_medium: {
    tiny: "0.7rem",
    small: "0.8rem",
    medium: "1rem",
    large: "1.2rem",
  },
  media_large: {
    tiny: "0.7rem",
    small: "0.8rem",
    medium: "1rem",
    large: "1.2rem",
  },
};

import { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../../../assets/colors/colors";
import { getTeamLogoById } from "../../../utils/getTeamLogoById";
import { getTeamNameById } from "../../../utils/getTeamNameById";
import InfoBox from "../../../components/common/InfoBox";
import TeamModal from "../../../components/TeamModal";
import { FaSort, FaPlus, FaMinus } from "react-icons/fa";
import { fontSize } from "../../../assets/style/font";

interface InnerFixtureDataProps {
  teamInfo: any;
  currentRound: number;
  fixturesDifficultyScore: any;
}

const InnerFixtureData: React.FC<InnerFixtureDataProps> = ({ teamInfo, currentRound }) => {
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [teamData, setTeamData] = useState({});
  const [teamInfoAll, setTeamInfoAll] = useState([] as any);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortedScore, setSortedScore] = useState([] as any);
  const [sorted, setSorted] = useState(false);
  const [fixtureCount, setFixtureCount] = useState<number>(5);

  useEffect(() => {
    calculateFixtureDifficulty();
  }, [fixtureCount, teamInfo, sorted]); // Re-calculate whenever fixtureCount, teamInfo, or sorted changes

  const calculateFixtureDifficulty = () => {
    const newFixturesDifficultyScore = teamInfo.map((team: any) => {
      let score = 0;
      for (let i = currentRound; i < currentRound + fixtureCount; i++) {
        if (team.fixtures[i] && team.fixtures_difficulty[i]) {
          score += team.fixtures_difficulty[i][0] ? 10 / team.fixtures_difficulty[i][0] : 0;
          score += team.fixtures_difficulty[i][1] ? 10 / team.fixtures_difficulty[i][1] : 0;
        }
      }
      return { ...team, difficultyScore: score };
    });

    setSortedScore(newFixturesDifficultyScore);
  };

  const handleOpenTeamModal = (data: any) => {
    setIsTeamModalOpen(true);
    setTeamData(data);
  };

  const handleCloseTeamModal = () => {
    setIsTeamModalOpen(false);
  };

  const sortTeamsByDifficulty = () => {
    const sortedData = [...sortedScore].sort((a: any, b: any) => {
      return sortOrder === "asc" ? a.difficultyScore - b.difficultyScore : b.difficultyScore - a.difficultyScore;
    });
    setSorted(true);
    setTeamInfoAll(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const increaseFixtureCount = () => {
    if (fixtureCount < 10) {
      setFixtureCount(fixtureCount + 1);
    }
  };

  const decreaseFixtureCount = () => {
    if (fixtureCount > 1) {
      setFixtureCount(fixtureCount - 1);
    }
  };

  const innerFixtureData = (data: any) => {
    const teamData = sorted ? teamInfoAll : sortedScore;
    return (
      <FixturesDataContainer onClick={() => handleOpenTeamModal(data)}>
        <LeftData>
          <TeamInfo>
            <TeamLogo src={getTeamLogoById(teamData, data.id, "id")} alt="logo" />
            <TeamName>{getTeamNameById(teamData, data.id)}</TeamName>
          </TeamInfo>
        </LeftData>
        <FixturesRightData>
          <Points>{data.difficultyScore.toFixed(1)}</Points>
          <OpponentsContainer>
            {data.fixtures.map((element: any, index: number) => {
              return (
                <>
                  {data.fixtures[index][0] && index >= currentRound && index < currentRound + fixtureCount ? (
                    <OpponentLogos key={index}>
                      <OpponentLogoBox location={data.fixtures_location[index][0]}>
                        <OpponentLogo src={getTeamLogoById(teamData, data.fixtures[index][0], "id")} alt="logo" />
                      </OpponentLogoBox>
                      {data.fixtures_difficulty[index][1] && (
                        <OpponentLogoBox location={data.fixtures_location[index][1]}>
                          <OpponentLogo src={getTeamLogoById(teamData, data.fixtures[index][1], "id")} alt="logo" />
                        </OpponentLogoBox>
                      )}
                    </OpponentLogos>
                  ) : null}
                  {!data.fixtures[index][0] && index >= currentRound && index < currentRound + fixtureCount && (
                    <BlankSpace key={index}>1</BlankSpace>
                  )}
                </>
              );
            })}
          </OpponentsContainer>
        </FixturesRightData>
      </FixturesDataContainer>
    );
  };

  return (
    <BoxContainer>
      <TeamModal
        isOpen={isTeamModalOpen}
        onClose={handleCloseTeamModal}
        allTeamInfo={teamInfo}
        teamInfo={teamData}
        currentRound={currentRound}
      />
      <Box>
        <BoxTitle>
          <TitleLeft>
            <TitleText>Fixtures Difficulty</TitleText>
          </TitleLeft>
          <Matches>
            <FaSortIcon onClick={sortTeamsByDifficulty} />
            <span>Matches</span>
            <FixtureCountButtons>
              <FixtureCountButton onClick={decreaseFixtureCount}>
                <FaMinus />
              </FixtureCountButton>
              <span>{fixtureCount}</span>
              <FixtureCountButton onClick={increaseFixtureCount}>
                <FaPlus />
              </FixtureCountButton>
            </FixtureCountButtons>
          </Matches>
        </BoxTitle>
        <Detail>
          <span>Home</span> <div style={{ backgroundColor: colors.home }} />
          <span>Away</span> <div style={{ backgroundColor: colors.away }} />
        </Detail>
        {sorted
          ? teamInfoAll.map((data: any) => innerFixtureData(data))
          : sortedScore.map((data: any) => innerFixtureData(data))}
      </Box>
    </BoxContainer>
  );
};

export default InnerFixtureData;

// Styles for the component

const BoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const BoxTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: ${colors.baseText};
  padding: 0.5rem;
`;

const Detail = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: ${fontSize.media_large.medium};
  padding: 0rem;
  color: ${colors.baseText};

  span {
    margin-right: 0.2rem;
  }

  div {
    width: ${fontSize.media_large.medium};
    height: ${fontSize.media_large.medium};
    border-radius: 25%;
    background-color: ${({ color }) => color};
    margin-right: 0.5rem;
  }

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium};
  }
`;

const TitleLeft = styled.div`
  display: flex;
  align-items: center;
`;

const FaSortIcon = styled(FaSort)`
  margin-left: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: rotate(180deg);
  }
`;

const FixtureCountButtons = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;

  span {
    font-size: ${fontSize.media_large.medium};
    margin: 0 0.5rem;
    font-weight: bold;
    color: ${colors.baseText};
  }

  @media (max-width: 768px) {
    span {
      font-size: ${fontSize.media_small.medium};
    }
  }
`;

const FixtureCountButton = styled.button`
  background-color: ${colors.buttonBase};
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  font-size: ${fontSize.media_large.medium};

  &:hover {
    background-color: ${colors.banner};
    color: ${colors.textWhite};
  }

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium};
    width: 1.2rem;
    height: 1.2rem;
  }
`;

const Box = styled.div`
  width: 100%;
  margin: 3px;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
    50% {
      transform: scale(1.05);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
  }

  @media (max-width: 1268px) {
    width: 100%;
  }
`;

const FixturesDataContainer = styled.div`
  display: flex;
  justify-content: left;
  margin-top: 0.3rem;
  padding: 0.5rem;

  @media (max-width: 768px) {
    padding: 0rem;
  }
`;

const BlankSpace = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  @media (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const TeamInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: left;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TeamName = styled.div`
  font-size: ${fontSize.media_large.medium};
  display: flex;
  color: ${colors.baseText};
  align-items: left;

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium};
  }
`;

const OpponentLogos = styled.div`
  display: flex;
  flex-direction: column;
`;

const OpponentLogoBox = styled.div<{ location: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  border-radius: 25%;
  background-color: ${({ location }) => (location === "a" ? colors.away : colors.home)};
`;

const OpponentsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const Points = styled.div`
  font-size: ${fontSize.media_large.medium};
  display: flex;
  align-items: center;
  text-align: right;
  color: ${colors.baseText};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium};
  }
`;

const TeamLogo = styled.img`
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
`;

const OpponentLogo = styled(TeamLogo)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0;
  @media (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const LeftData = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 50%;
`;

const RightData = styled.div`
  @media (max-width: 768px) {
    display: flex;
    cursor: pointer;
    align-items: center;
  }
`;

const FixturesRightData = styled(RightData)`
  display: flex;
  justify-content: right;
  align-items: right;
  width: 50%;

  @media (max-width: 768px) {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
  }
`;

const TitleText = styled.span`
  font-size: ${fontSize.media_large.medium};
  font-weight: bold;
  color: ${colors.baseText};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.medium};
  }
`;

const Matches = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: ${fontSize.media_large.medium};
    font-weight: bold;
    color: ${colors.baseText};
  }

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    font-size: ${fontSize.media_small.large};
    span {
      font-size: ${fontSize.media_small.medium};
    }
  }
`;

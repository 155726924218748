import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../assets/colors/colors";
import { fontSize } from "../assets/style/font";
import InfoBox from "./common/InfoBox";
import { Title } from "../assets/style/Title";
import { getPoints } from "../api/miniLeague/getPoints";
import { useRecoilState, useRecoilValue } from "recoil";
import { dataState } from "../state/data";
import Button from "./common/Button";
import { IoMdRefresh } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { searchState } from "../state/search";
import { optionState } from "../state/option";
import { getManagerData } from "../api/manager/getManagerData";
import CaptainBarChart from "./CaptainBarChart";
import ReactPaginate from "react-paginate";
import { FaSortUp, FaSortDown } from "react-icons/fa"; // Sort icons

const ITEMS_PER_PAGE = 15;
const REFRESH_COOLDOWN_TIME = 300000; // 5분 (300,000 밀리초)

interface MiniLeagueRankingTableData {
  leagueData: any[];
}

const MiniLeagueRankingTable: React.FC<MiniLeagueRankingTableData> = ({ leagueData }) => {
  // const [pointsData, setPointsData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);
  const [cooldownTimeLeft, setCooldownTimeLeft] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: string } | null>(null);
  const [searchQuery, setSearchQuery] = useState(""); // 검색어 상태

  const data = useRecoilValue(dataState);
  const [search, setSearch] = useRecoilState(searchState);
  const [option, setOption] = useRecoilState(optionState);
  let navigate = useNavigate();

  // Refresh All 기능
  const handleRefreshAll = async () => {
    setIsCooldown(true); // 쿨타임 시작
    setCooldownTimeLeft(REFRESH_COOLDOWN_TIME); // 쿨타임 시간 설정
    setLoading(true);
    for (let point of leagueData) {
      await getManagerData(point.entry, data.currentRound);
    }
    setLoading(false);
  };

  // 쿨타임 타이머 관리
  useEffect(() => {
    if (isCooldown && cooldownTimeLeft > 0) {
      const interval = setInterval(() => {
        setCooldownTimeLeft((prev) => prev - 1000);
      }, 1000);

      return () => clearInterval(interval); // 타이머 클리어
    } else if (cooldownTimeLeft <= 0) {
      setIsCooldown(false); // 쿨타임 끝나면 다시 버튼 활성화
    }
  }, [isCooldown, cooldownTimeLeft]);

  const handleLoad = async (entry: any, event: React.MouseEvent) => {
    event.stopPropagation();
    setLoading(true);
    await getManagerData(entry, data.currentRound);
    setLoading(false);
  };

  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected);
  };

  const handleRowClick = (entry: any) => {
    setSearch({ ...search, managerId: entry });
    setOption({ ...option, managerMenuOption: "search" });
    navigate(`/manager`);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setCurrentPage(0); // 검색어가 변경되면 페이지를 첫 페이지로 초기화
  };

  const filteredData = React.useMemo(() => {
    return leagueData.filter((item) => item.entry_name.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [leagueData, searchQuery]);

  const sortedData = React.useMemo(() => {
    let sortableData = [...filteredData];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [filteredData, sortConfig]);

  // 현재 페이지에 표시할 데이터 계산
  const currentItems = React.useMemo(() => {
    const start = currentPage * ITEMS_PER_PAGE;
    return sortedData.slice(start, start + ITEMS_PER_PAGE);
  }, [sortedData, currentPage]);

  const requestSort = (key: string) => {
    let direction = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key: string) => {
    if (!sortConfig) return null;
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? <FaSortUp /> : <FaSortDown />;
    }
    return null;
  };

  return (
    <Container>
      <RankingContainer>
        <RankingTitle>FPL Universe League Ranking</RankingTitle>
        <TableWrapper>
          <RankingTable>
            <thead>
              <tr>
                <TableHeader onClick={() => requestSort("rank")}>Rank {getSortIcon("rank")}</TableHeader>
                <TableHeader onClick={() => requestSort("entry_name")}>Manager {getSortIcon("entry_name")}</TableHeader>
                <TableHeader onClick={() => requestSort("total")}>Total {getSortIcon("total")}</TableHeader>
                <TableHeader onClick={() => requestSort("event_total")}>Round {getSortIcon("event_total")}</TableHeader>
                <TableHeader onClick={() => requestSort("total_transfers")}>
                  Transfers {getSortIcon("total_transfers")}
                </TableHeader>
                <TableHeader onClick={() => requestSort("overall_rank")}>
                  World Rank {getSortIcon("overall_rank")}
                </TableHeader>
                <TableHeader>Captain</TableHeader>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((data: any) => (
                <TableRow key={data.rank} onClick={() => handleRowClick(data.entry)}>
                  <TableData>{data.rank}</TableData>
                  <TableData>{data.entry_name}</TableData>
                  <TableData>{data.total}</TableData>
                  <TableData>{data.event_total}</TableData>
                  <TableData>{data.total_transfers}</TableData>
                  <TableData>{data.overall_rank ? data.overall_rank : "—"}</TableData>
                  <TableData>{data.current_captain ? data.current_captain : "—"}</TableData>
                </TableRow>
              ))}
            </tbody>
          </RankingTable>
        </TableWrapper>

        <PaginationContainer>
          <SearchInput
            type="text"
            placeholder="Search by Manager Name"
            value={searchQuery}
            onChange={handleSearchChange}
          />

          <PaginationBox>
            <RefreshAllButton
              onClick={handleRefreshAll}
              disabled={isCooldown || loading} // 쿨타임 동안 버튼 비활성화
            >
              {isCooldown ? `Cool: ${Math.ceil(cooldownTimeLeft / 1000)}s` : "Refresh All"}
            </RefreshAllButton>
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              pageCount={Math.ceil(leagueData.length / ITEMS_PER_PAGE)}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          </PaginationBox>
        </PaginationContainer>
      </RankingContainer>
    </Container>
  );
};

export default MiniLeagueRankingTable;

// Styled Components
const Container = styled.div`
  /* background-color: ${colors.rankingBackground}; */
  border-radius: 1rem;
  padding: 0rem;
  margin: 0rem;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    margin: 0.5rem 0;
    width: 100%;
    padding: 0rem;
  }
`;

const RankingContainer = styled.div`
  padding: 1rem;

  @media (max-width: 768px) {
    margin: 0.5rem 0;
    width: 100%;
    padding: 0rem;
  }
`;

const RankingTitle = styled(Title)`
  color: ${colors.baseText};
  margin-bottom: 1.5rem;
  font-size: ${fontSize.media_large.large};
  text-align: left;
  margin-left: 2rem;

  @media (max-width: 768px) {
    margin-top: 0.5rem;
    text-align: center;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const RankingTable = styled.table`
  width: 100%;
  margin: auto;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background-color: ${colors.tableHeader};
  color: ${colors.textWhite};
  padding: 1rem 0.5rem;
  font-size: ${fontSize.media_large.small};
  text-transform: uppercase;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.small};
    padding: 0.2rem;
  }
`;

const TableRow = styled.tr`
  cursor: pointer;
  &:hover {
    background-color: ${colors.tableRowHover};
  }
`;

const TableData = styled.td`
  padding: 0.5rem;
  color: ${colors.baseText};
  text-align: center;
  border-bottom: 1px solid ${colors.baseBorder};
  font-size: ${fontSize.media_large.small};

  @media (max-width: 768px) {
    font-size: ${fontSize.media_small.small};
    padding: 0.3rem;
  }
`;

// Pagination 스타일링
const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1;
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    gap: 1rem;
    float: left;
    text-align: center;
    cursor: pointer;

    @media (max-width: 768px) {
      text-align: center;
      margin: 0;
      padding: 0;
      margin-top: 0.5rem;
    }
  }

  .pagination__link {
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    border: 1px solid ${colors.tableBorder};
    color: ${colors.baseText};
    cursor: pointer;
    font-size: ${fontSize.media_large.small};
    transition: background-color 0.3s ease, color 0.3s ease;
    display: inline-block;
    text-align: center;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 768px) {
      font-size: ${fontSize.media_small.small};
    }
  }

  .pagination__link--active {
    background-color: ${colors.buttonBase2};
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    color: ${colors.textWhite};
  }

  .pagination__link--disabled {
    color: ${colors.baseText};
    cursor: pointer;
  }

  .pagination__link:hover:not(.pagination__link--disabled) {
    background-color: ${colors.buttonHover};
    color: ${colors.textWhite};
    cursor: pointer;
  }
`;

const SearchInput = styled.input`
  width: 50%;
  padding: 0.5rem;
  font-size: ${fontSize.media_large.small};
  color: ${colors.baseText};
  background: ${colors.inputBackground};
  outline: none;
  border-radius: 0.75rem;
  border: none;
  text-align: center;

  &:focus {
    border-color: ${colors.buttonBase};
  }

  @media (max-width: 768px) {
    width: 90%;
    margin-top: 0.5rem;
    padding: 0.3rem;
  }
`;

const PaginationBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RefreshAllButton = styled(Button)`
  background-color: ${colors.buttonBase2};
  color: ${colors.textWhite};
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  height: 2rem;
  font-size: ${fontSize.media_large.medium};

  &:disabled {
    background-color: ${colors.disabledButton};
    cursor: not-allowed;
  }

  &:hover:enabled {
    background-color: ${colors.buttonHover};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

import axios from "axios";

export const getPoints = async () => {
  try {
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
    const response = await axios.get(`${serverUrl}/api/miniLeague/points`);
    return response;
  } catch (error) {
    console.error("Error fetching mini league points:", error);
  }
};

import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./page/Home";
import About from "./page/About";
import Player from "./page/Player";
import Manager from "./page/Manager";
import styled from "styled-components";
import Team from "./page/Team";
import Login from "./page/Login";
import { RecoilRoot } from "recoil";
import Mypage from "./page/Mypage";
import Navbar from "./components/Navbar";
import TypeTest from "./page/TypeTest";
import LoadingScreen from "./page/Home/components/LoadingScreen";
import HeaderComponent from "./components/Header";
import MiniLeaguePage from "./page/MiniLeague";
import { DataProvider, useDataContext } from "./context/DataProvider";
import colors from "./assets/colors/colors";
import { NAVBAR_EXPAND_WIDTH } from "./constants/const";

const AppContent = () => {
  const { teamInfo, playerInfo, isLoading } = useDataContext();

  if (isLoading) {
    return <LoadingScreen location="app" />;
  }

  return (
    <Application>
      <Navbar />
      <HeaderComponent playerInfo={playerInfo} allTeamInfo={teamInfo} />
      <Content>
        <Routes>
          <Route path="" element={<Home playerInfo={playerInfo} allTeamInfo={teamInfo} />} />
          <Route path="home" element={<Home playerInfo={playerInfo} allTeamInfo={teamInfo} />} />
          <Route path="league" element={<MiniLeaguePage />} />
          <Route path="about" element={<About />} />
          <Route path="player" element={<Player playerInfo={playerInfo} allTeamInfo={teamInfo} />} />
          <Route path="team" element={<Team />} />
          <Route path="manager" element={<Manager playerInfo={playerInfo} allTeamInfo={teamInfo} />} />
          <Route path="login" element={<Login />} />
          <Route path="test" element={<TypeTest />} />
          <Route path="mypage" element={<Mypage />} />
        </Routes>
      </Content>
    </Application>
  );
};

function App() {
  return (
    <RecoilRoot>
      <DataProvider>
        <AppContent />
      </DataProvider>
    </RecoilRoot>
  );
}

export default App;

const Application = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${colors.baseBackground};
  color: black;
`;

const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
  transition: margin 0.3s;
  background-color: ${colors.baseBackground};

  @media (max-width: ${NAVBAR_EXPAND_WIDTH}) {
    padding-left: 0;
    margin-top: 5rem;
  }
`;

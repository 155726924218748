import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist({
  key: "recoil-persist", // this key is using to store data in local storage
  storage: localStorage, // configure which storage to use (e.g., localStorage, sessionStorage)
});

export const dataState = atom({
  key: "dataState",
  default: {
    loaded: false,
    currentRound: 0,
    allPlayerInfo: [],
    allTeamInfo: [],
  },
  effects_UNSTABLE: [persistAtom], // add this line to enable persistence
});

import React, { useState } from "react";
import styled from "styled-components";
import SearchBar from "./SearchBar";
import PlayerModal from "./PlayerModal";
import { useRecoilState, useRecoilValue } from "recoil";
import { dataState } from "../state/data";
import { searchState } from "../state/search";
import { useNavigate } from "react-router-dom";
import { DEFAULT_WIDTH } from "../constants/const";
import BestPlayer from "../page/Home/components/BestPlayer";

interface HeaderData {
  playerInfo: any; // Replace 'any' with the actual type of your playerData
  allTeamInfo: any; // Replace 'any' with the actual type of your teamData
}

const HeaderComponent: React.FC<HeaderData> = ({ playerInfo, allTeamInfo }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [teamData, setTeamData] = useState<any>(null);
  const [playerData, setPlayerData] = useState<any>(null);
  const data = useRecoilValue(dataState);
  const [search, setSearch] = useRecoilState(searchState);
  let navigate = useNavigate();

  const handleOpenModal = (playerName: string) => {
    setIsModalOpen(true);
    let teamData: any;
    let targetPlayer = playerInfo.find((player: any) => {
      return getTargetPlayer(player, playerName, "exact");
    });
    if (!targetPlayer) {
      targetPlayer = playerInfo.find((player: any) => {
        return getTargetPlayer(player, player, "include");
      });
    }
    if (targetPlayer) {
      for (let i = 0; i < allTeamInfo.length; i++) {
        if (allTeamInfo[i].id === targetPlayer.team) {
          teamData = allTeamInfo[i];
          break;
        }
      }
      setTeamData(teamData);
      setPlayerData(targetPlayer);
    }
  };

  const getTargetPlayer = (player: any, playerName: string, type: string) => {
    try {
      const lowerCasePlayerName = playerName.toLowerCase();
      const lowerCaseFirstName = player.first_name.toLowerCase();
      const lowerCaseLastName = player.second_name.toLowerCase();
      const lowerCaseWebName = player.web_name.toLowerCase();
      if (type === "exact") {
        if (
          lowerCasePlayerName === lowerCaseFirstName ||
          lowerCasePlayerName === lowerCaseLastName ||
          lowerCasePlayerName === lowerCaseWebName
        ) {
          return player;
        }
      } else if (type === "include") {
        if (
          lowerCaseFirstName.includes(lowerCasePlayerName) ||
          lowerCaseLastName.includes(lowerCasePlayerName) ||
          lowerCaseWebName.includes(lowerCasePlayerName)
        ) {
          return player;
        }
      }
    } catch (error) {
      return null;
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSearchManagerId = (managerId: number) => {
    setSearch({
      ...search,
      managerId: managerId,
    });
  };

  const handleSearchManagerName = (managerName: string) => {
    setSearch({
      ...search,
      managerName: managerName,
    });

    // Redirect to manager page
    navigate(`/manager`);
  };

  return (
    <HeaderContainer>
      <HeaderComponents>
        <SearchBarContainer isSearchBarVisible={isSearchBarVisible}>
          <SearchBar
            openModal={handleOpenModal}
            searchManagerId={handleSearchManagerId}
            searchManagerName={handleSearchManagerName}
          />
        </SearchBarContainer>
        <BestPlayerContainer>
          <BestPlayer allTeamInfo={allTeamInfo} currentRound={data.currentRound} />
        </BestPlayerContainer>
        {/* <SearchIcon onClick={() => setIsSearchBarVisible(!isSearchBarVisible)}>
          <FaSearch />
        </SearchIcon> */}
      </HeaderComponents>
      {isModalOpen && (
        <PlayerModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          playerInfo={playerData}
          allTeamInfo={allTeamInfo}
          teamInfo={teamData}
          currentRound={data.currentRound}
        />
      )}
    </HeaderContainer>
  );
};

export default HeaderComponent;

const HeaderContainer = styled.div`
  background: linear-gradient(135deg, #0d1b2a 0%, #0f4c75 20%, #00bfa6 100%);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  padding: 1.5rem 1rem;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 0rem;
    margin-top: 1rem;
    margin-bottom: -4rem;
  }
`;

const HeaderComponents = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: ${DEFAULT_WIDTH};
  gap: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
    width: 100%;
    padding-top: 1rem;
  }
`;

const SearchBarContainer = styled.div<{ isSearchBarVisible: boolean }>`
  flex: 2;
  padding: 0rem;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

const BestPlayerContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
  }
`;

import { useState } from "react";
import styled from "styled-components";
import colors from "../../../assets/colors/colors";
import axios from "axios";
import { checkJWTExpire } from "../../../utils/checkJWT";
import { useRecoilState } from "recoil";
import { accountState } from "../../../state/account";

const CreateQuiz = () => {
  const [question, setQuestion] = useState("");
  const [choices, setChoices] = useState(["", "", "", ""]);
  const [account, setAccountState] = useRecoilState(accountState);

  const handleChoiceChange = (index: number, value: string) => {
    const updatedChoices = [...choices];
    updatedChoices[index] = value;
    setChoices(updatedChoices);
  };

  const handleSubmit = async () => {
    try {
      const token: string = localStorage.getItem("token") ? localStorage.getItem("token") : account.token;

      const jwtCheck = checkJWTExpire(token);
      if (!jwtCheck) {
        alert("Your session has expired. Please log in again.");
        localStorage.clear();
        setAccountState({
          username: "",
          email: "",
          fplId: 0,
          isLoggedIn: false,
        });
        window.location.href = "/";
      }
      const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
      const response = await axios.post(
        `${serverUrl}/api/quiz/create-quiz`,
        {
          question,
          data: {
            choices,
            openTime: new Date().getTime(),
            closeTime: new Date().getTime() + 1000 * 60 * 60 * 24 * 7,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Handle response if needed
      alert("Quiz created");
      // refresh page
      window.location.href = "/mypage";
    } catch (error) {
      console.error("Error creating quiz:", error);
    }
  };

  return (
    <Container>
      <Title>Create Quiz</Title>
      <InputContainer>
        <QuestionInput
          type="text"
          placeholder="Question Name"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
      </InputContainer>
      <Choices>
        {choices.map((choice, index) => (
          <ChoiceInput
            key={index}
            type="text"
            placeholder={`Choice ${index + 1}`}
            value={choice}
            onChange={(e) => handleChoiceChange(index, e.target.value)}
          />
        ))}
      </Choices>
      <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: ${colors.baseText};
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
`;

const QuestionInput = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  width: 300px;
  border: 1px solid ${colors.baseColor};
  border-radius: 5px;
`;

const Choices = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChoiceInput = styled.input`
  margin-bottom: 10px;
  padding: 8px;
  width: 300px;
  border: 1px solid ${colors.baseColor};
  border-radius: 5px;
`;

const SubmitButton = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: ${colors.baseColor};
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #16a085;
  }
`;

export default CreateQuiz;

import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import styled from "styled-components";
import axios from "axios";
import { useRecoilState } from "recoil";
import { accountState } from "../../state/account";
import colors from "../../assets/colors/colors";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [account, setAccountState] = useRecoilState(accountState);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    // Handle login logic here
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;

    const body = {
      email: email,
      password: password,
    };

    try {
      const response = await axios.post(`${serverUrl}/api/login`, body);

      localStorage.setItem("token", response.data.token);
      localStorage.setItem("username", response.data.username);
      localStorage.setItem("fplId", response.data.fplId);
      localStorage.setItem("email", response.data.email);
      const data = {
        username: response.data.username,
        email: response.data.email,
        fplId: response.data.fplId,
        token: response.data.token,
        isLoggedIn: true,
      };
      setAccountState(data);

      if (response.status === 500) {
        alert("Login failed. Try again");
      }

      window.location.href = "/mypage";
    } catch (err: any) {
      alert("Login failed. Try again");
    }
  };

  const handleRegister = async (event: any) => {
    event.preventDefault();
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;

    const body = {
      email: email,
      password: password,
    };

    try {
      const response = await axios.post(`${serverUrl}/api/register`, body);

      alert("Registration successful! Please log in.");

      if (response.status === 500) {
        alert("Registration failed. Try again");
      }
    } catch (err: any) {
      console.log(err);
      alert("Registration failed. Try again");
    }
  };

  return (
    <Container>
      <LoginForm onSubmit={handleSubmit}>
        <Title>Login</Title>
        <InputField type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <InputField
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <ButtonGroup>
          <ActionButton type="submit" onClick={handleSubmit}>
            Log In
          </ActionButton>
          <ActionButton type="button" onClick={handleRegister}>
            Register
          </ActionButton>
        </ButtonGroup>
      </LoginForm>
    </Container>
  );
};

export default Login;

const Container = styled.div`
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: ${colors.baseBackground};
`;

const LoginForm = styled.form`
  width: 100%;
  max-width: 400px;
  padding: 4rem;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-bottom: 10rem;

  @media (max-width: 500px) {
    padding: 20px;
    width: 90%;
  }
`;

const Title = styled.h1`
  color: ${colors.textBlack};
  margin-bottom: 20px;
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 1rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const ActionButton = styled.button`
  width: 48%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: ${colors.buttonBase};
  color: white;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${colors.buttonHover};
  }

  &:focus {
    outline: none;
  }
`;

import styled from "styled-components";
import { getTopManagerWorld } from "../../../api/manager/getTopManagerWorld";
import { useEffect, useState } from "react";
import { get } from "http";

const TopManagers = () => {
  const [topManagers, setTopManagers] = useState([]);

  const getData = async () => {
    try {
      const res = await getTopManagerWorld();
      setTopManagers(res);
    } catch (error) {
      console.error("Error fetching top manager world:", error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Subtitle>Top Manager in the World</Subtitle>
      <Header>
        <Column>Rank</Column>
        <Column>Name</Column>
        <Column>Total</Column>
        <Column>Event</Column>
        <Column>Id</Column>
      </Header>
      {topManagers.map((manager: any, index: number) => {
        return (
          <Answer key={index}>
            <AnswerComponent>{manager.rank}</AnswerComponent>
            <AnswerComponent>{manager.entry_name}</AnswerComponent>
            <AnswerComponent>{manager.total}</AnswerComponent>
            <AnswerComponent>{manager.event_total}</AnswerComponent>
            <AnswerComponent>{manager.entry}</AnswerComponent>
          </Answer>
        );
      })}
    </>
  );
};

export default TopManagers;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
`;

const Column = styled.h3`
  text-align: center;
  width: 20%;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const Answer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 8px 0;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const AnswerComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid #eee;
  padding: 8px 0;
  width: 20%;
  font-size: 1rem;
  word-wrap: break-word; // Allows long words to be able to be broken and wrap onto the next line
  overflow-wrap: break-word; // Ensure long words break and wrap to the next line
  white-space: normal; // Allow wrapping of text in case of long strings without spaces

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const Subtitle = styled.h2`
  margin-bottom: 20px;
`;

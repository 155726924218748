import { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import axios, { all } from "axios";
import ReactModal from "react-modal";
import colors from "../../assets/colors/colors";
import { getTeamLogoById } from "../../utils/getTeamLogoById";
import PlayerModal from "../../components/PlayerModal";
import PlayerInfoBox from "./components/PlayerInfoBox";
import Container from "../../components/common/Container";
import { useRecoilState, useRecoilValue } from "recoil";
import { optionState } from "../../state/option";
import { getPlayerImgSrcById } from "../../utils/getPlayerImgSrcById";
import { dataState } from "../../state/data";
import HeaderComponent from "../../components/Header";
import { PageContainer } from "../../components/common/PageContainer";
import { fontSize } from "../../assets/style/font";
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from "react-icons/fa";
import Pagination from "../../components/Pagination";

ReactModal.setAppElement("#root"); // Assuming your root element has the ID 'root'

const questionLists = [
  "BEST PICKS",
  "BEST Differentials",
  "Top Transfer In",
  "Top Transfer Out",
  "Most underpriced Forward",
  "Most underpriced Midfielder",
  "Most underpriced Defender",
  "Most underpriced Goalkeeper",
];

const answerHeaders = [
  ["Name", "Team", "Point", "Recent", "xGI", "xGI per 90", "Fixtures", "Next", "Price", "Selected", "Score"],
  ["Name", "Team", "Point", "Recent", "xGI", "xGI per 90", "Fixtures", "Next", "Price", "Selected", "Score"],
  ["Name", "Team", "Point", "Recent", "xGI", "xGI per 90", "Fixtures", "Next", "Price", "Selected", "Transfers In"],
  ["Name", "Team", "Point", "Recent", "xGI", "xGI per 90", "Fixtures", "Next", "Price", "Selected", "Transfers Out"],
  ["Name", "Team", "Point", "Recent", "xGI", "xGI per 90", "Fixtures", "Next", "Price", "Selected", "-"],
  ["Name", "Team", "Point", "Recent", "xGI", "xGI per 90", "Fixtures", "Next", "Price", "Selected", "-"],
  ["Name", "Team", "Point", "Recent", "xGI", "xGI per 90", "Fixtures", "Next", "Price", "Selected", "-"],
  ["Name", "Team", "Point", "Recent", "xGI", "xGI per 90", "Fixtures", "Next", "Price", "Selected", "-"],
];

const ITEMS_PER_PAGE = 10; // ✅ 페이지당 항목 개수

function Player({ playerInfo, allTeamInfo }: any) {
  const [playerFacts, setPlayerFacts] = useState<any[]>([]);
  // const [questionIndex, setQuestionIndex] = useState<number>(options.playerMenuOption ? options.playerMenuOption : 0);
  const questionIndex = useRecoilValue(optionState).playerMenuOption;
  console.log("questionIndex", questionIndex);
  const [answers, setAnswers] = useState<Array<Array<string>>>([[]]);
  const [teamInfo, setTeamInfo] = useState<any[]>([]);
  const globalData = useRecoilValue(dataState);
  const [isPlayerModalOpen, setIsPlayerModalOpen] = useState(false);
  const [playerData, setPlayerData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1); // ✅ 숫자 타입으로 변경

  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"
  const [sortColumn, setSortColumn] = useState(""); // Column name to sort

  const handleOpenPlayerModal = (data: any) => {
    let teamData: any;
    for (let i = 0; i < allTeamInfo.length; i++) {
      if (allTeamInfo[i].id === data.team) {
        teamData = allTeamInfo[i];
        break;
      }
    }
    setIsPlayerModalOpen(true);
    setPlayerData(data);
    setTeamInfo(teamData);
  };

  const handleClosePlayerModal = () => {
    setIsPlayerModalOpen(false);
  };

  const getAnswer = async (index: number) => {
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
    setIsLoading(true);

    try {
      if (index < 4) {
        const endpoint = ["watchable", "differential", "transfer-in", "transfer-out"][index];
        const response = await axios.get(`${serverUrl}/api/player/top-players/${endpoint}`);
        setAnswers(response.data);
      } else {
        const positions = ["forward", "midfielder", "defender", "goalkeeper"];
        const positionIndex = index - 4;
        const response = await axios.get(`${serverUrl}/api/player/top-players/underpriced`, {
          params: { position: positions[positionIndex] },
        });
        setAnswers(response.data);
      }
    } catch (error) {
      console.error("Error fetching answers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPlayerFacts = async () => {
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
    const response: any = await axios.get(`${serverUrl}/api/common/facts/players`);
    setPlayerFacts(response.data);
  };

  useEffect(() => {
    getAnswer(questionIndex);
    console.log("questionIndex", questionIndex);
  }, [questionIndex]);

  useEffect(() => {
    getPlayerFacts();
  }, []);

  const handleSort = (column: string) => {
    setSortColumn(column);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const getOpponentLogo = (data: any, index: number) => {
    if (data.fixtures[globalData.currentRound + 1][0]) {
      return (
        <td style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <OpponentLogoBox>
            <OpponentLogo
              src={getTeamLogoById(allTeamInfo, data.fixtures[globalData.currentRound + 1][0], "id")}
              alt="logo"
            />
          </OpponentLogoBox>
          {data.fixtures_difficulty[globalData.currentRound + 1][1] && (
            <OpponentLogoBox>
              <OpponentLogo src={getTeamLogoById(allTeamInfo, data.fixtures[index][1], "id")} alt="logo" />
            </OpponentLogoBox>
          )}
        </td>
      );
    }
  };

  const uniqueData = (data: any) => {
    if (questionIndex === questionLists.indexOf("BEST PICKS")) {
      return (
        <td>
          <Overall value={data.overall_score}> {data.overall_score ? data.overall_score : "-"}</Overall>
        </td>
      );
    } else if (questionIndex === questionLists.indexOf("BEST Differentials")) {
      return (
        <td>
          <Overall value={data.differential_score}> {data.differential_score ? data.differential_score : "-"}</Overall>
        </td>
      );
    } else if (questionIndex === questionLists.indexOf("Top Transfer In")) {
      return <td>{data.transfers_in_event}</td>;
    } else if (questionIndex === questionLists.indexOf("Top Transfer Out")) {
      return <td>{data.transfers_out_event}</td>;
    } else if (
      questionIndex === questionLists.indexOf("Most underpriced Forward") ||
      questionIndex === questionLists.indexOf("Most underpriced Midfielder") ||
      questionIndex === questionLists.indexOf("Most underpriced Defender") ||
      questionIndex === questionLists.indexOf("Most underpriced Goalkeeper")
    ) {
      return <td>-</td>;
    }
  };

  // ✅ useMemo를 사용하여 정렬된 데이터를 캐싱 (answers가 변경될 때만 재계산)
  const sortedData = useMemo(() => {
    if (!answers || answers.length === 0) return [];

    let data = [...answers];

    if (sortColumn) {
      data.sort((a, b) => {
        const valueA = a[sortColumn as keyof typeof a];
        const valueB = b[sortColumn as keyof typeof b];

        if (valueA < valueB) return sortOrder === "asc" ? -1 : 1;
        if (valueA > valueB) return sortOrder === "asc" ? 1 : -1;
        return 0;
      });
    }

    return data;
  }, [answers, sortColumn, sortOrder]);

  // ✅ 페이지네이션 데이터를 slice로 추출
  const paginatedAnswers = useMemo(() => {
    if (!sortedData || sortedData.length === 0) return [];
    return sortedData.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);
  }, [sortedData, currentPage]);

  // ✅ 질문이 바뀔 때 페이지를 1로 초기화
  useEffect(() => {
    setCurrentPage(1);
  }, [answers]);

  const totalPages = Math.ceil(sortedData.length / ITEMS_PER_PAGE); // ✅ 전체 페이지 수 계산

  if (isLoading || !paginatedAnswers.length) {
    return <div>Loading...</div>;
  }
  console.log(paginatedAnswers[0]);
  return (
    <PlayerContainer>
      {isPlayerModalOpen && (
        <PlayerModal
          isOpen={isPlayerModalOpen}
          onClose={handleClosePlayerModal}
          playerInfo={playerData}
          allTeamInfo={allTeamInfo}
          teamInfo={teamInfo}
          currentRound={globalData.currentRound}
        />
      )}

      <PlayerInfoBox playerFacts={playerFacts} />

      <Target>{questionLists[questionIndex]}</Target>
      <AnswerTable>
        <table>
          <thead>
            <tr>
              {answerHeaders[questionIndex].map((header, index) => (
                <th key={index} onClick={() => handleSort(header.toLowerCase())}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedAnswers.map((answer: any, index: number) => (
              <tr key={index} onClick={() => handleOpenPlayerModal(answer)}>
                <td>{answer.web_name}</td>
                <td>
                  <img src={getTeamLogoById(allTeamInfo, answer.team, "id")} alt="logo" width="20px" />
                </td>
                <td>{answer.total_points}</td>
                <td>{answer.recent_average_points}</td>
                <td>{answer.expected_goal_involvements}</td>
                <td>{answer.expected_goal_involvements_per_90}</td>
                <td>{answer.fixtures_difficulty_sum || "-"}</td>
                <td>{answer.fixtures ? getOpponentLogo(answer, index) : "-"}</td>
                <td>{answer.now_cost ? answer.now_cost / 10 : "-"}</td>
                <td>{answer.selected_by_percent ? `${answer.selected_by_percent}%` : "-"}</td>
                {uniqueData(answer)}
              </tr>
            ))}
            {/* {paginatedAnswers.map((answer: any, index: number) => answerData(answer, index))} */}
          </tbody>
        </table>
      </AnswerTable>
      <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={(page) => setCurrentPage(page)} />
    </PlayerContainer>
  );
}

export default Player;

const PlayerContainer = styled(PageContainer)``;

const Target = styled.h3`
  margin-bottom: 20px;
  color: ${colors.baseText};
`;

const AnswerTable = styled.div`
  width: 100%;
  overflow-x: auto; /* ✅ 가로 스크롤 활성화 */
  -webkit-overflow-scrolling: touch; /* ✅ 모바일에서 부드러운 스크롤 */

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px; /* ✅ 테이블이 너무 좁아지지 않도록 최소 너비 지정 */
  }

  thead {
    background: ${colors.primary}; /* ✅ 헤더 배경색 */
    color: white;
    font-weight: bold;
  }

  th,
  td {
    color: ${colors.baseText};
    padding: 0.5rem;
    text-align: center;
    font-size: ${fontSize.media_large.medium};
  }

  tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.1); /* ✅ 행 hover 효과 */
    cursor: pointer;
  }

  @media (max-width: 768px) {
    table {
      min-width: 600px; /* ✅ 모바일에서 가로 스크롤 가능하도록 설정 */
    }

    th,
    td {
      padding: 6px;
      font-size: ${fontSize.media_small.medium};
    }
  }
`;

const Overall = styled.span<{ value: number }>`
  font-weight: bold;
  color: ${(props) => {
    if (props.value >= 100) {
      return colors.score0th;
    } else if (props.value >= 90) {
      return colors.score1st;
    } else if (props.value >= 80) {
      return colors.score2nd;
    } else if (props.value >= 70) {
      return colors.score3rd;
    } else if (props.value >= 60) {
      return colors.score4th;
    } else {
      return colors.score5th;
    }
  }};
`;

const TeamLogo = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin: auto;
  display: block;

  @media (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const OpponentLogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OpponentLogo = styled(TeamLogo)`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0;
  @media (max-width: 768px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

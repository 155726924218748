import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  margin: auto;
  width: 100%;

  @media (max-width: 768px) {
  }
`;

export default Container;

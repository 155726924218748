import React from "react";
import styled from "styled-components";
import Transfer from "./Transfer";
import colors from "../../../assets/colors/colors";

const BestPlayerAndTransfer: React.FC<{ allTeamInfo: any; currentRound: number }> = ({ allTeamInfo, currentRound }) => {
  return (
    <Container>
      <Section>
        <Transfer />
      </Section>
    </Container>
  );
};

export default BestPlayerAndTransfer;

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 1rem;
  overflow: hidden;
  width: 100%;

  /* background: linear-gradient(145deg, ${colors.bestPlayerBackground}, #b8dddd); */

  @media (max-width: 1440px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Section = styled.div`
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1440px) {
    width: 100%;
    padding: 0.5rem;
  }
`;

const Divider = styled.div`
  width: 1px;
  background-color: ${colors.line};
  height: 22rem;
  margin: auto 0;

  @media (max-width: 1440px) {
    width: 90%;
    height: 1px;
    margin: 0.5rem 0;
  }
`;

import styled from "styled-components";
import colors from "../../../assets/colors/colors";
import { getPositionById } from "../../../utils/getPositionById";
import { useRecoilValue } from "recoil";
import { dataState } from "../../../state/data";
import { getTeamLogoById } from "../../../utils/getTeamLogoById";
import { useEffect, useState } from "react";

const SquadDetails = ({ squad }: any) => {
  const commonData = useRecoilValue(dataState);
  const [transfersInPoints, setTransfersInPoints] = useState(0);
  const [transfersOutPoints, setTransfersOutPoints] = useState(0);
  const [transfersCost, setTransfersCost] = useState(0);
  const [netProfitLoss, setNetProfitLoss] = useState(0);

  useEffect(() => {
    if (squad.picks.length > 0) {
      const transfersIn = squad.picks.filter((player: any) => player.transferred_in === true);
      const transfersOut = squad.prev_picks.filter((player: any) => player.transferred_out === true);

      const transfersInPoints = transfersIn.reduce(
        (sum: number, player: any) => sum + (player.totalPoints || player.event_points || 0),
        0
      );
      const transfersOutPoints = transfersOut.reduce(
        (sum: number, player: any) => sum + (player.totalPoints || player.event_points || 0),
        0
      );
      const transfersCost = squad.entry_history.event_transfers_cost;
      const netProfitLoss = transfersInPoints - transfersOutPoints - transfersCost;

      setTransfersInPoints(transfersInPoints);
      setTransfersOutPoints(transfersOutPoints);
      setTransfersCost(transfersCost);
      setNetProfitLoss(netProfitLoss);
    }
  }, []);

  const calculateSum = (players: any[], key: string) =>
    players.reduce((sum: number, player: any) => {
      const value = player["is_captain"] ? player[key] * 2 : player[key] || 0;
      return sum + value;
    }, 0);

  const renderRows = (players: any[]) =>
    players.map((player: any, index: number) => (
      <DetailsRow key={index}>
        <DetailsData>
          <TeamLogo src={getTeamLogoById(commonData.allTeamInfo, player.team, "id")} alt="logo" />
        </DetailsData>
        <DetailsData>
          {player.web_name} {player.is_captain ? <span>C</span> : ""}
        </DetailsData>
        <DetailsData>{getPositionById(player.element_type)}</DetailsData>
        <DetailsData>{player.goalsScored || 0}</DetailsData>
        <DetailsData>{player.assists || 0}</DetailsData>
        <DetailsData>{player.bonus || 0}</DetailsData>
        <DetailsData>{player.minutes || 0}</DetailsData>
        <DetailsData>
          {player.is_captain ? (
            <>
              {player.totalPoints * 2}({player.totalPoints})
            </>
          ) : (
            player.totalPoints || 0
          )}
        </DetailsData>
      </DetailsRow>
    ));

  const renderSumRow = (players: any[]) => (
    <DetailsRow isSum>
      <DetailsData colSpan={3}>Total</DetailsData>
      <DetailsData>{calculateSum(players, "goalsScored")}</DetailsData>
      <DetailsData>{calculateSum(players, "assists")}</DetailsData>
      <DetailsData>{calculateSum(players, "bonus")}</DetailsData>
      <DetailsData>{calculateSum(players, "minutes")}</DetailsData>
      <DetailsData>{calculateSum(players, "totalPoints") || calculateSum(players, "event_points")}</DetailsData>
    </DetailsRow>
  );

  const startingPlayers = squad.picks.filter((player: any) => player.position <= 11);
  const benchPlayers = squad.picks.filter((player: any) => player.position > 11);
  const transfersIn = squad.picks.filter((player: any) => player.transferred_in === true);
  const transfersOut = squad.prev_picks.filter((player: any) => player.transferred_out === true);

  return (
    <Container>
      <Section>
        <SectionTitle>Starting 11</SectionTitle>
        <DetailsTable>
          <thead>
            <DetailsHeaderRow>
              <DetailsHeader>Team</DetailsHeader>
              <DetailsHeader>Player Name</DetailsHeader>
              <DetailsHeader>Position</DetailsHeader>
              <DetailsHeader>Goals</DetailsHeader>
              <DetailsHeader>Assists</DetailsHeader>
              <DetailsHeader>Bonus</DetailsHeader>
              <DetailsHeader>Minutes Played</DetailsHeader>
              <DetailsHeader>Points</DetailsHeader>
            </DetailsHeaderRow>
          </thead>
          <tbody>
            {renderRows(startingPlayers)}
            {renderSumRow(startingPlayers)}
          </tbody>
        </DetailsTable>
      </Section>

      <Section>
        <SectionTitle>Bench</SectionTitle>
        <DetailsTable>
          <thead>
            <DetailsHeaderRow>
              <DetailsHeader>Team</DetailsHeader>
              <DetailsHeader>Player Name</DetailsHeader>
              <DetailsHeader>Position</DetailsHeader>
              <DetailsHeader>Goals</DetailsHeader>
              <DetailsHeader>Assists</DetailsHeader>
              <DetailsHeader>Bonus</DetailsHeader>
              <DetailsHeader>Minutes Played</DetailsHeader>
              <DetailsHeader>Points</DetailsHeader>
            </DetailsHeaderRow>
          </thead>
          <tbody>
            {renderRows(benchPlayers)}
            {renderSumRow(benchPlayers)}
          </tbody>
        </DetailsTable>
      </Section>

      <Section>
        <SectionTitle>Transfers IN / OUT</SectionTitle>
        <SectionSubTitle>Transfers In</SectionSubTitle>
        <DetailsTable>
          <thead>
            <DetailsHeaderRow>
              <DetailsHeader>Team</DetailsHeader>
              <DetailsHeader>Player Name</DetailsHeader>
              <DetailsHeader>Position</DetailsHeader>
              <DetailsHeader>Goals</DetailsHeader>
              <DetailsHeader>Assists</DetailsHeader>
              <DetailsHeader>Bonus</DetailsHeader>
              <DetailsHeader>Minutes Played</DetailsHeader>
              <DetailsHeader>Points</DetailsHeader>
            </DetailsHeaderRow>
          </thead>
          <tbody>
            {renderRows(transfersIn)}
            {renderSumRow(transfersIn)}
          </tbody>
        </DetailsTable>
        <SectionSubTitle>Transfers Out</SectionSubTitle>
        <DetailsTable>
          <thead>
            <DetailsHeaderRow>
              <DetailsHeader>Team</DetailsHeader>
              <DetailsHeader>Player Name</DetailsHeader>
              <DetailsHeader>Position</DetailsHeader>
              <DetailsHeader>Goals</DetailsHeader>
              <DetailsHeader>Assists</DetailsHeader>
              <DetailsHeader>Bonus</DetailsHeader>
              <DetailsHeader>Minutes Played</DetailsHeader>
              <DetailsHeader>Points</DetailsHeader>
            </DetailsHeaderRow>
          </thead>
          <tbody>
            {renderRows(transfersOut)}
            {renderSumRow(transfersOut)}
          </tbody>
        </DetailsTable>
        <DetailsTable>
          <thead>
            <DetailsHeaderRow>
              <DetailsHeader>Transfers In Points</DetailsHeader>
              <DetailsHeader>Transfers Out Points</DetailsHeader>
              <DetailsHeader>Transfers Cost</DetailsHeader>
              <DetailsHeader>Net Profit / Loss</DetailsHeader>
            </DetailsHeaderRow>
          </thead>
          <tbody>
            <DetailsData>{transfersInPoints}</DetailsData>
            <DetailsData>{transfersOutPoints}</DetailsData>
            <DetailsData>{transfersCost}</DetailsData>
            <DetailsData>{netProfitLoss}</DetailsData>
          </tbody>
        </DetailsTable>
      </Section>
    </Container>
  );
};

export default SquadDetails;

// Styled Components

const Container = styled.div`
  margin-top: 1rem;
  width: 100%;
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h3`
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: ${colors.textBlack};
`;

const SectionSubTitle = styled.h4`
  margin-bottom: 1rem;
  font-size: 1rem;
  color: ${colors.textBlack};
`;

const DetailsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  background-color: ${colors.infoBoxBackground};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
`;

const DetailsHeaderRow = styled.tr`
  background-color: ${colors.tableHeader};
`;

const DetailsHeader = styled.th`
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  text-align: center;
  color: ${colors.textWhite};
  text-transform: capitalize;

  &:first-child {
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
`;

const DetailsRow = styled.tr<{ isSum?: boolean }>`
  /* &:nth-child(even) {
    background-color: ${colors.tableRow};
  } */

  &:hover {
    background-color: ${colors.tableRowHover};
  }

  ${({ isSum }) =>
    isSum &&
    `
    font-weight: bold;
    background-color: ${colors.sumBackground};

    &:hover {
      background-color: ${colors.sumBackground};
  `}
`;

const DetailsData = styled.td`
  padding: 0.3rem 0.5rem;
  font-size: 0.9rem;
  text-align: center;
  color: ${colors.textBlack};
  border-bottom: 1px solid ${colors.baseBorder};

  span {
    font-size: 0.8rem;
    color: ${colors.textWhite};
    background-color: ${colors.textBlack};
    border-radius: 50%;
    margin-left: 0.2rem;
    padding: 0.1rem 0.3rem;
  }
`;

const TeamLogo = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
`;

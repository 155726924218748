export const getManagerImgSrcByTeamId = (id: number) => {
  const managerId: Array<any> = [
    0,
    51018, // arsenal,
    37569, // aston villa,
    "Photo-Missing", // bournemouth,
    42831, // brentford,
    47376, // brighton,
    "Photo-Missing", // burnley,
    38223, // chelsea mauricio pochettino
    66115, // crystal palace
    40853, // everton sean dyche
    41801, // fulham marco silva
    279, // liverpool jurgen klopp
    "Photo-Missing", // luton town rob edwards
    37974, // man city pep guardiola
    41668, // man utd erik ten hag
    38216, // newcastle eddie howe
    41809, // nottingham forest nuno espirito santo
    39183, // sheffield united chris wilder
    41881, // spurs ange postecoglou
    85, // west ham david moyes
    58884, // wolves gary oneil
  ];

  if (managerId[id] === "Photo-Missing") {
    return `https://resources.premierleague.com/premierleague/photos/players/110x140/Photo-Missing.png`;
  } else {
    return `https://resources.premierleague.com/premierleague/photos/players/110x140/man${managerId[id]}.png`;
  }
};

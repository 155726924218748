import React, { useState } from "react";
import styled from "styled-components";
import colors from "../../../assets/colors/colors";
import { Title } from "../../../assets/style/Title";
import { getManagerSquad } from "../../../api/manager/getManagerSquad";
import { useRecoilValue } from "recoil";
import { dataState } from "../../../state/data";
import SquadDetails from "./SquadDetail";
import { getPlayerEvent } from "../../../api/player/getPlayerEvent";

const EventHistory = ({ managerId, managerData }: any) => {
  const [expandedRow, setExpandedRow] = useState<number | null>(null); // Tracks the expanded row
  const [squadData, setSquadData] = useState<any>(null); // Holds squad data
  const [loadingSquad, setLoadingSquad] = useState(false); // Tracks API call state
  const commonData = useRecoilValue(dataState);

  const handleRowClick = (event: number) => {
    if (expandedRow === event) {
      // Collapse the row if it's already expanded
      setExpandedRow(null);
      setSquadData(null);
    } else {
      // Expand and fetch data
      setExpandedRow(event);
      fetchSquadData(event);
    }
  };

  const fetchSquadData = async (event: number) => {
    try {
      setLoadingSquad(true);
      let resManagerSquad = await getManagerSquad(managerId, event);
      console.log(resManagerSquad, event);

      // 이전 week 데이터 가져오기
      if (event > 1) {
        let resPrevManagerSquad = await getManagerSquad(managerId, event - 1);
        resManagerSquad.prev_picks = resPrevManagerSquad.picks;
      }
      await matchPlayerInfo(resManagerSquad);
      // setSquadData(resManagerSquad);
      setLoadingSquad(false);
    } catch (error) {
      console.error("Error fetching squad data:", error);
      setLoadingSquad(false);
    }
  };

  const matchPlayerInfo = async (squadData: any) => {
    try {
      const playerData: [] = commonData.allPlayerInfo;

      // Mark transferred in players
      if (squadData.prev_picks) {
        squadData.picks = squadData.picks.map((pick: any) => {
          const prevPick = squadData.prev_picks.find((prevPick: any) => prevPick.element === pick.element);
          return { ...pick, transferred_in: !prevPick };
        });

        // Mark transferred out players
        const updatedPrevPicks = await Promise.all(
          squadData.prev_picks.map(async (prevPick: any) => {
            const pickExists = squadData.picks.find((pick: any) => pick.element === prevPick.element);
            if (!pickExists) {
              const updatedPrevPick = await getPlayerEvent(prevPick.element, prevPick.round + 1);
              return { ...updatedPrevPick, element: prevPick.element, transferred_out: true };
            }
            return { ...prevPick, transferred_out: false };
          })
        );

        squadData.prev_picks = updatedPrevPicks;
      }

      // Match player info with picks
      const addPlayerInfo = (players: any[]) =>
        players.map((player: any) => {
          const matchedPlayer = playerData.find((info: any) => info.id === player.element);
          if (!matchedPlayer) return player;

          // Add only new fields from matchedPlayer
          const additionalFields = Object.keys(matchedPlayer).reduce((acc: any, key: string) => {
            if (!(key in player)) {
              acc[key] = matchedPlayer[key];
            }
            return acc;
          }, {});

          return { ...player, ...additionalFields };
        });

      const updatedPicks = addPlayerInfo(squadData.picks);
      const updatedPrevPicks = squadData.prev_picks ? addPlayerInfo(squadData.prev_picks) : [];

      // Set updated squad data
      setSquadData({ ...squadData, picks: updatedPicks, prev_picks: updatedPrevPicks });
    } catch (error) {
      console.error("Error matching player info:", error);
    }
  };

  return (
    <Container>
      {managerData ? (
        <TableWrapper>
          <HistoryTable>
            <thead>
              <TableHeaderRow>
                <TableHeader>Event</TableHeader>
                <TableHeader>Event Rank</TableHeader>
                <TableHeader>Points</TableHeader>
                <TableHeader>Bench Points</TableHeader>
                <TableHeader>Total Points</TableHeader>
                <TableHeader>Top %</TableHeader>
                {/* <TableHeader>Performance</TableHeader> */}
              </TableHeaderRow>
            </thead>
            <tbody>
              {managerData.ranks.map((event: any, index: any) => (
                <>
                  <TableRow
                    key={index}
                    onClick={() => handleRowClick(event.event)}
                    isExpanded={expandedRow === event.event}
                  >
                    <TableData>{event.event}</TableData>
                    <TableData>{event.rank ? event.rank.toLocaleString() : ""}</TableData>
                    <TableData>{event.points}</TableData>
                    <TableData>{event.bench_points}</TableData>
                    <TableData>{event.total_points}</TableData>
                    <TableData>
                      <TopPercentage>
                        TOP {event.percentage}%
                        <PercentileBarWrapper>
                          <PercentileBar width={100 - event.percentage} />
                        </PercentileBarWrapper>
                      </TopPercentage>
                    </TableData>
                    {/* <TableData>
                    <PerformanceIndicator rank={event.rank_sort}>
                      {event.rank_sort < 0 ? (
                        <FaArrowUp color="green" />
                      ) : event.rank_sort > 0 ? (
                        <FaArrowDown color="red" />
                      ) : (
                        <FaEquals color="gray" />
                      )}
                    </PerformanceIndicator>
                  </TableData> */}
                  </TableRow>
                  {expandedRow === event.event && (
                    <TableRow>
                      <TableData colSpan={6}>
                        {loadingSquad ? (
                          <LoadingMessage>Loading Squad...</LoadingMessage>
                        ) : squadData ? (
                          <SquadDetails squad={squadData} />
                        ) : (
                          <LoadingMessage>No Data Available</LoadingMessage>
                        )}
                      </TableData>
                    </TableRow>
                  )}
                </>
              ))}
            </tbody>
          </HistoryTable>
        </TableWrapper>
      ) : (
        <LoadingMessage>Loading...</LoadingMessage>
      )}
    </Container>
  );
};

export default EventHistory;

const Container = styled.div`
  margin-top: 2rem;
  width: 100%;
`;

const EventHistoryTitle = styled(Title)`
  font-size: 1.5rem;
  color: ${colors.textBlack};
  margin-bottom: 1rem;
  text-align: center;
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const HistoryTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  background-color: ${colors.infoBoxBackground};
`;

const TableHeaderRow = styled.tr`
  background-color: ${colors.tableHeader};
`;

const TableHeader = styled.th`
  padding: 0.75rem 1rem;
  color: ${colors.textWhite};
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
`;

const TableRow = styled.tr<{ isExpanded?: boolean }>`
  &:nth-child(even) {
    background-color: ${colors.tableRow};
  }

  &:hover {
    background-color: ${colors.tableRowHover};
  }

  ${({ isExpanded }) =>
    isExpanded &&
    `
    background-color: ${colors.expandedRow};
  `}
`;

const TableData = styled.td`
  padding: 0.75rem 1rem;
  color: ${colors.textBlack};
  text-align: center;
  font-size: 0.9rem;
  border-bottom: 1px solid ${colors.baseBorder};

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
`;

const TopPercentage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PercentileBarWrapper = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${colors.baseBorder};
  border-radius: 5px;
  overflow: hidden;
  margin-top: 5px;
`;

const PercentileBar = styled.div<{ width: number }>`
  height: 100%;
  background-color: ${(props) => {
    if (props.width >= 99) return colors.top1;
    if (props.width >= 95) return colors.top5;
    if (props.width >= 90) return colors.top10;
    if (props.width >= 80) return colors.top20;
    if (props.width >= 60) return colors.top40;
    if (props.width >= 40) return colors.top60;
    return colors.top100;
  }};
  width: ${(props) => props.width}%;
`;

const PerformanceIndicator = styled.div<{ rank: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0.5;
  font-size: 1.2rem;

  svg {
    margin-left: 5px;
  }
`;

const LoadingMessage = styled.p`
  text-align: center;
  color: ${colors.textBlack};
  font-size: 1rem;
`;

// const SquadDetails = styled.div`
//   background-color: ${colors.infoBoxBackground};
//   padding: 1rem;
//   border: 1px solid ${colors.baseBorder};
//   border-radius: 8px;

//   h3 {
//     margin-bottom: 1rem;
//     color: ${colors.textBlack};
//   }

//   ul {
//     list-style-type: none;
//     padding: 0;

//     li {
//       margin-bottom: 0.5rem;
//       font-size: 0.9rem;
//     }
//   }
// `;

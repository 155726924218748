import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import ReactModal from "react-modal";
import Button from "../../components/common/Button";
import colors from "../../assets/colors/colors";
import Container from "../../components/common/Container";
import { PageContainer } from "../../components/common/PageContainer";

ReactModal.setAppElement("#root"); // Assuming your root element has the ID 'root'

function Team() {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<Array<string>>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const questionLists = ["League Table"];

  const answerHeaders = [["R", "Team", "W", "D", "L", "Point", "+/-", "Diff"]];

  const getAnswer = async () => {
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
    const teamInfo = await axios.get(`${serverUrl}/api/team/all-team-info`);
    const sortedTeamInfo = teamInfo.data.sort((a: any, b: any) => a.position - b.position);

    setAnswers(sortedTeamInfo);
  };

  useEffect(() => {
    getAnswer();
  }, [questionIndex]);

  const answerData = (data: any) => {
    // console.log(data);
    if (questionIndex === 0) {
      return (
        <AnswerData>
          <AnswerComponent>{data.position}</AnswerComponent>
          <AnswerComponent>{data.short_name}</AnswerComponent>
          <AnswerComponent>{data.win}</AnswerComponent>
          <AnswerComponent>{data.draw}</AnswerComponent>
          <AnswerComponent>{data.loss}</AnswerComponent>
          <AnswerComponent>{data.points}</AnswerComponent>
          <AnswerComponent>
            {data.goalsFor} / {data.goalsAgainst}
          </AnswerComponent>
          <AnswerComponent>{data.goalDifference}</AnswerComponent>
        </AnswerData>
      );
    }
  };

  return (
    <TeamContainer>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={customStyles} // Optional: Custom styles
      >
        {questionLists.map((question, index) => (
          <QuestionList
            key={index}
            onClick={() => {
              setQuestionIndex(index);
              setIsModalOpen(false);
            }}
          >
            {question}
          </QuestionList>
        ))}
      </ReactModal>

      <Answer>
        <Target>League Table</Target>
        <AnswerHeader>
          {answerHeaders[questionIndex].map((header, index) => (
            <AnswerComponent key={index}>{header}</AnswerComponent>
          ))}
        </AnswerHeader>

        {answers.length > 0 && answers.map((answer: any) => answerData(answer))}
      </Answer>
    </TeamContainer>
  );
}

export default Team;

const TeamContainer = styled(PageContainer)``;

const Title = styled.h1`
  color: ${colors.baseText};
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    width: "100%",
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "rgba(0,0,0,0)", // transparent
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
};

const Question = styled(Button)`
  margin-bottom: 20px;
  // add shadow
  box-shadow: 0 4px 8px rgba(0, 0, 0, 1);
`;

const Target = styled.h2`
  margin-bottom: 20px;
  color: ${colors.baseText};
`;

const QuestionList = styled.div`
  background-color: #2c3e50;
  color: white;
  padding: 1.5rem;
  margin: 10px 10px; // Spacing between items
  flex: 0 0 auto; // Flex item does not grow or shrink
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
`;

const AnswerHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr); // Adjust the number of columns as necessary
  text-align: center;
  padding: 10px 0;
  font-size: 0.9rem; // Adjust font size as necessary
  font-weight: bold;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Answer = styled.div`
  background-color: ${colors.baseBackground};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 20px 0; // Spacing around the table
  overflow: hidden; // Ensures the border-radius is applied to children
`;

const AnswerData = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  text-align: center;
  padding: 10px 0;
  font-size: 0.8rem; // Adjust font size as necessary
  color: #bdc3c7; // Data text color
  &:not(:last-child) {
    border-bottom: 1px solid #7f8c8d; // Adds a divider between rows
  }
`;

const AnswerComponent = styled.div`
  word-wrap: break-word; // Allows long words to be able to be broken and wrap onto the next line
  overflow-wrap: break-word; // Ensure long words break and wrap to the next line
  white-space: normal; // Allow wrapping of text in case of long strings without spaces
  color: ${colors.baseText};
`;

export const getPositionById = (id: number) => {
  switch (id) {
    case 1:
      return "Goalkeeper";
    case 2:
      return "Defender";
    case 3:
      return "Midfielder";
    case 4:
      return "Forward";
    default:
      return "Unknown";
  }
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MiniLeagueRankingTable from "../../../components/MiniLeagueRankingTable";
import { getPoints } from "../../../api/miniLeague/getPoints";
import CaptainChartContainer from "./CaptainChartContainer";
import InfoBox from "../../../components/common/InfoBox";
import colors from "../../../assets/colors/colors";

const MiniLeagueSection = () => {
  const [leagueData, setLeagueData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [isCooldown, setIsCooldown] = useState(false);
  const [cooldownTimeLeft, setCooldownTimeLeft] = useState(0);

  // 쿨타임 타이머 관리
  useEffect(() => {
    if (isCooldown && cooldownTimeLeft > 0) {
      const interval = setInterval(() => {
        setCooldownTimeLeft((prev) => prev - 1000);
      }, 1000);

      return () => clearInterval(interval); // 타이머 클리어
    } else if (cooldownTimeLeft <= 0) {
      setIsCooldown(false); // 쿨타임 끝나면 다시 버튼 활성화
    }
  }, [isCooldown, cooldownTimeLeft]);

  useEffect(() => {
    const fetchNewEntries = async () => {
      try {
        const results: any = await getPoints();
        const points = results.data;
        setLeagueData(points);
      } catch (error) {
        console.error("Error fetching new entries:", error);
      }
    };
    fetchNewEntries();
  }, [loading]);

  return (
    <RankingContainer>
      <MiniLeagueRankingTable leagueData={leagueData} />
      <CaptainChartContainer leagueData={leagueData} />
    </RankingContainer>
  );
};

export default MiniLeagueSection;

// Styled Components

const RankingContainer = styled.div`
  margin: 1rem;
  text-align: center;
  width: 100%;
  height: auto;

  @media (max-width: 768px) {
    margin: 0rem;
  }
`;

import axios from "axios";

export const getManagerData = async (manager: any, event: number) => {
  try {
    const serverUrl = process.env.REACT_APP_SERVER_ADDRESS;
    const url: string = `${serverUrl}/api/manager/${manager}/${event}`;
    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    console.error("Error fetching data from FPL:", error);
  }
};

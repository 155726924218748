import styled from "styled-components";
import MiniLeagueNews from "./components/MiniLeagueNews";
import MiniLeagueRanking from "./components/MiniLeagueRanking";
import MiniLeagueNewEntries from "./components/MiniLeagueNewEntries";
import MiniLeagueTopTransfers from "./components/MiniLeagueTopTransfers";
import { PageContainer } from "../../components/common/PageContainer";

const MiniLeaguePage = () => {
  return (
    <PageContainer>
      <h1>Mini League Overview</h1>
      <ContentGrid>
        <MiniLeagueNews />
        <MiniLeagueNewEntries />
      </ContentGrid>
      <MiniLeagueRanking />
      <MiniLeagueTopTransfers />
    </PageContainer>
  );
};

export default MiniLeaguePage;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`;
